export default {
  SET_NOTIFICATION_LIST(state, payload) {
    state.notificationList = {
      list: payload.list,
      count: payload.count,
    };
  },
  SET_USER_NOTIFICATION_LIST(state, payload) {
    state.userNotificationList = {
      list: payload.list,
      count: payload.count,
    };
  },
  DELETE_USER_NOTIFICATION(state, id) {
    state.userNotificationList.list = state.userNotificationList.list.filter(item => item.id !== id)
    state.userNotificationList.count--;
  }
}