import { CommonConstants } from '@/constants/common'

export default {
  bind(el, binding, vnode) {
    el.addEventListener(
      'blur',
      (e) => {
        let value;
        value = e.target.value.replace(CommonConstants.ALPHABET_HIRAGANA_REGEX, '');
        if (e.target.maxLength > 0 && value.length > e.target.maxLength) {
          e.preventDefault();
          value = value.slice(0, -1);
        }
        e.target.value = value;
        el.value = value;

        let event;
        if (typeof Event === 'function') {
          event = new Event('input', { bubbles: true });
        } else {
          event = document.createEvent('Event');
          event.initEvent('input', true, true);
        }
        vnode.elm.dispatchEvent(event);
      },
      true
    );
  },
};
