import Vue from 'vue';
import Vuex from 'vuex';
import alert from './alert';
import authModule, { initialAuth } from './modules/auth';
import commonModule, { initialCommon } from './modules/common';
import historyModule, { initialHistory } from './modules/history';
import settingUserModule, { initialSettingUser } from './modules/setting/user';
import settingUserCpmModule, { initialSettingUserCpm } from './modules/setting/userCpm';
import newsModule, { initialNews } from './modules/news';
import couponModule, { initialCoupon } from './modules/coupon';
import eventModule, { initialEvent } from './modules/event';
import galleryCategoryModule, { initialGallery } from './modules/shop/gallery';
import clientModule, { initialClient } from './modules/client';
import shopModule, { initialShop } from './modules/shop';
import menuCategoryModule, { initialMenu } from './modules/shop/menu';
import customerModule, { initialCustomer } from './modules/customer';
import aggregateModule, { initialAggregate } from './modules/aggregate';
import accountModule, { initialAccount } from './modules/account';
import groupModule, { initialGroup } from './modules/group';
import notificationModule, { initialNotification } from './modules/notification';
import qrCodeModule, { initialQrCode } from './modules/shop/qrCode';
import currencyModule, { initialCurrency } from './modules/currency';
import attendStoreModule, { initialAttendStore } from './modules/attend';
import parentPointModule, { initialParentPoint } from './modules/parentPoint';
import childPointModule, { initialChildPoint } from './modules/childPoint';
Vue.use(Vuex);

const initialState = {
  auth: initialAuth(),
  common: initialCommon(),
  history: initialHistory(),
  settingUser: initialSettingUser(),
  settingUserCpm: initialSettingUserCpm(),
  news: initialNews(),
  coupon: initialCoupon(),
  event: initialEvent(),
  gallery: initialGallery(),
  client: initialClient(),
  shop: initialShop(),
  menu: initialMenu(),
  customer: initialCustomer(),
  aggregate: initialAggregate(),
  account: initialAccount(),
  group: initialGroup(),
  notification: initialNotification(),
  qrCode: initialQrCode(),
  currency: initialCurrency(),
  attend: initialAttendStore(),
  parentPoint: initialParentPoint(),
  childPoint: initialChildPoint()
}

export default new Vuex.Store({
  modules: {
    alert,
    auth: authModule,
    common: commonModule,
    history: historyModule,
    settingUser: settingUserModule,
    settingUserCpm: settingUserCpmModule,
    news: newsModule,
    coupon: couponModule,
    event: eventModule,
    gallery: galleryCategoryModule,
    client: clientModule,
    shop: shopModule,
    menu: menuCategoryModule,
    customer: customerModule,
    aggregate: aggregateModule,
    account: accountModule,
    group: groupModule,
    notification: notificationModule,
    qrCode: qrCodeModule,
    currency: currencyModule,
    attend: attendStoreModule,
    parentPoint: parentPointModule,
    childPoint: childPointModule
  },
  mutations: {
    RESET_ALL(state){
      Object.keys(initialState).forEach(key => {
        state[key] = {...initialState[key]}
      })
    }
  }
});
