export default {
  SET_COUPON_LIST(state, payload) {
    state.couponList = payload;
  },
  SET_COUPON_COUNT(state, payload) {
    state.couponCount = payload;
  },
  SET_ALL_EXPIRED(state, payload) {
    state.allInvalid = payload;
  },
  SET_COUPON_DETAIL(state, payload) {
    state.couponDetail = payload;
  },
  SET_COUPON_USE_LIST(state, payload) {
    state.couponUseList = payload;
  },
  SET_COUPON_USE_COUNT(state, payload) {
    state.couponUseCount = payload;
  },
  SET_COUPON_USE_TOTAL(state, payload) {
    state.couponUseTotal = payload;
  },
  SET_COUPON_INVIDUAL_LIST(state, payload) {
    state.couponIndividualList = payload;
  },
  SET_COUPON_INVIDUAL_COUNT(state, payload) {
    state.couponIndividualCount = payload;
  },
};
