export default {
  SET_CATEGORIES_LIST(state, list) {
    state.categoriesList.list = list;
  },
  SET_CATEGORY_COUNT(state, count) {
    state.categoriesList.count = count;
  },
  SET_PARENT_POINT_DETAIL(state, list) {
    state.parentPointDetail = list;
  },
  SET_CUSTOMER_LIST(state, list) {
    state.customerList.list = list;
  },
  SET_COUNT_CUSTOMER_LIST(state, count) {
    state.customerList.count = count;
  },
  SET_HISTORY_POINT_LIST(state, list) {
    state.historyPointList.list = list;
  },
  SET_HISTORY_POINT_COUNT(state, count) {
    state.historyPointList.count = count;
  },
  SET_HISTORY_POINT(state, point) {
    state.historyPointList.point = point;
  },
  SET_HISTORY_PLANNED_POINT(state, plannedPoint) {
    state.historyPointList.plannedPoint = plannedPoint;
  },
  SET_POINT_QR_PULLDOWN(state, list) {
    state.pointQRCodePulldown = list;
  },
  SET_HISTORY_POINT_TYPE_LIST(state, list) {
    state.historyPointTypeList.list = list;
  },
  SET_SHOP_POINT_LIST(state, list) {
    state.shopPointList.list = list;
  },
  RESET_SHOP_POINT_LIST(state) {
    state.shopPointList = {
      list: [],
    };
  },
  SET_POINT_STORE_LIST(state, list) {
    state.pointStoreList.list = list;
  },
  SET_POINT_STORE_COUNT(state, count) {
    state.pointStoreList.count = count;
  },
  SET_AGGREGRATE_POINT_DETAIL(state, list) {
    state.aggregratePointDetail = list;
  },
  SET_CUSTOMER_POINT_ACTIVITIES(state, list) {
    state.customerPointActivities = list;
  },
  SET_POINT_QR_LIST(state, list) {
    state.pointQRList.list = list;
  },
  SET_POINT_QR_COUNT(state, count) {
    state.pointQRList.count = count;
  },
  SET_PARENT_POINT_QR_DETAIL(state, pointQrDetail) {
    state.pointQrDetail = pointQrDetail;
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
};
