import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialCustomer = () => ({
  customerList: {
    list: [],
    count: 0,
  },
  customerTotal: 0,
  customerCpmTotal: 0,
  totalUnsubscribe: 0,
  customerActivities: [],
  customerDetail: {},
  hasCustomerTotal: false,
  templatesList: {
    list: [],
    count: 0,
  },
  newsList: {
    list: [],
    count: 0,
  },
  templateDetail: {},
  newsDetail: {},
  childList: {
    list: [],
    count: 0,
  },
  existsOmiseNumber: false,
});

export default {
  state: initialCustomer(),
  mutations,
  actions,
  getters,
  namespaced: true
};
