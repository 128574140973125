export default {
  SET_MENU_LIST(state, payload) {
    return (state.menuList = payload);
  },
  SET_CATEGORY_LIST(state, payload) {
    return (state.categoryList = payload);
  },
  SET_MENU_DETAIL(state, payload) {
    return (state.menuDetail = payload);
  },
};
