export const removeNullFromObject = (params) => {
  return Object.entries(params)
    .reduce((obj, [key, value]) => {
      if (typeof value === 'undefined' || value === null) {
        return obj
      }
      return (obj[key] = value, obj)
    }, {})
}

export const getToken = () => {
  let encodedToken = localStorage.getItem('token') || '';
  if (encodedToken) {
    return atob(encodedToken.slice(10));
  }
  return '';
}

export const getEncodedRefreshToken = () => {
  const encodedRefreshToken = localStorage.getItem('refreshToken');
  if (!encodedRefreshToken) return;
  return btoa(encodedRefreshToken);
}
