import http from '@/config/api';
import router from '@/router';

export default {
  async getGroupList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`/event/${subdomain}/groups`, { params })
      .then((response) => {
        if (response) commit('SET_GROUP_LIST', response.data);
      })
  },
  createGroup(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/group`, payload);
  },
  updateGroup(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/group/${payload.id}`, payload);
  },
  deleteGroup(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.delete(`/event/${subdomain}/group/${payload.id}`)
  },
  addShopToGroup(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/shops/group/${payload.id}`, payload);
  },
  removeShopFromGroup(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.delete(`/event/${subdomain}/shops/group/${payload.id}`, {
      data: {
        allFlag: payload.allFlag,
        shops: payload.shops,
      }
    });
  },
}
