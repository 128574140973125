import permission from '@/helpers/permission';
import router from '@/router';

export default {
  isLoading: (state) => state.isLoading,
  isButtonLoading: (state) => state.isButtonLoading,
  errors: (state) => state.errors,
  errorFields: (state) => state.errorFields,
  isErrorModalOpened: (state) => state.isErrorModalOpened,
  cardTemplateList: (state) => state.cardTemplateList,
  uploadedImage: (state) => state.uploadedImage,
  chargeAmountChoices: (state) => state.chargeAmountChoices,
  genres: (state) => state.genres,
  paymentCycles: (state) => state.paymentCycles,
  prefectures: (state) => state.prefectures,
  municipalities: (state) => state.municipalities,
  topicTemplates: (state) => state.topicTemplates,
  backgroundTemplates: (state) => state.backgroundTemplates,
  userPrivileges: (state) => state.userPrivileges,
  uploadErrors: (state) => state.uploadErrors,
  convenienceStores: (state) => state.convenienceStores.list,
  isOldEvent: (state) => state.isOldEvent,
  subdomain: (state, getters) => {
    const subdomain = localStorage.getItem('SUBDOMAIN') || window.location.host.split('.')[0] || null;
    if (getters.isOldEvent) {
      return localStorage.getItem('SUBDOMAIN');
    }
    return subdomain || state.subdomain || router.currentRoute.params.subdomain;
  },
  isGmoOrOfficeUrl: (_, getters) => {
    return ((localStorage.getItem('SUBDOMAIN') === 'management') && !getters.isOldEvent) ||
      !(getters.subdomain && getters.subdomain !== 'management');
  },
  isGmoOrOfficeLoginStore: (_, getters) => {
    return permission.isGmoOrOffice() && !getters.isGmoOrOfficeUrl;
  },
  isGmoOrOfficeLoginStoreAndStoreGroup: (_, getters) => {
    return permission.isStoreOrGroup() || getters.isGmoOrOfficeLoginStore;
  },
  isGmoOrOfficeLoginStoreAndStore: (_, getters) => {
    return permission.isStore() || getters.isGmoOrOfficeLoginStore;
  },
};
