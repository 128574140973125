import http from '@/config/api';
import router from '@/router';
import { generateRandomString } from '@/helpers/common';

const setTokenStorage = (state) => {
  localStorage.setItem('token', generateRandomString(10) + btoa(state.token));
  localStorage.setItem('refreshToken', generateRandomString(10) + btoa(state.refreshToken));
};

export default {
  login({ commit, getters, state }, payload) {
    const salt = btoa(payload.client_id + ',' + new Date().getTime());
    return http.post('/token', {
      ...payload,
      grantType: "client_credentials",
      salt: salt,
    }).then((response) => {
      if (response) {
        const { accessToken, passwordExpiresAt, refreshToken, authEmailFlag, isBlockAuthen } = response.data;
        const domain = process.env.VUE_APP_DOMAIN;
        const refreshTokenInfo = { salt, refreshToken, domain };
        commit('SET_PASSWORD_EXPIRES_AT', passwordExpiresAt);
        commit('SET_AUTH_EMAIL', authEmailFlag);
        commit('SET_TOKEN', accessToken);
        commit('SET_REFRESH_TOKEN', JSON.stringify(refreshTokenInfo));
        commit('IS_BLOCK_AUTHEN', isBlockAuthen);
        if (passwordExpiresAt && !getters.isPasswordExpired && !authEmailFlag) {
          setTokenStorage(state);
        }
      }
      return response?.data;
    });
  },
  changePassword({ state }, payload){
    return http.post('/account/password', payload).then((response) => {
      if (response) setTokenStorage(state);
      return response?.data;
    });
  },
  verifyCode({ getters, state }, payload){
    return http.post('/verify/code', payload).then((response) => {
      if (response && getters.passwordExpiresAt && !getters.isPasswordExpired) setTokenStorage(state);
      return response?.data;
    });
  },
  resendCode(){
    return http.post(`/code`).then((response) => {
      return response?.data;
    });
  },
  checkVerifyCode() {
    return http.get('/check-block-verify').then((response) => {
      return response?.data?.isBlockedAuthen;
    });
  },
  async getUserInfor({ commit, state }) {
    await http.get('/user').then((response) => {
      if (response) {
        const { data } = response;
        if (state.passwordExpiresAt !== data?.passwordExpire) {
          commit('SET_PASSWORD_EXPIRES_AT', data?.passwordExpire);
        }
        commit("SET_INFOR", data);
      }
    });
  },
  async logout({ commit }) {
    await http.delete('/logout').then(() => {
      commit('RESET_LOCAL_STORAGE');
      commit('RESET_ALL', '', { root: true });
      router.push({ name: 'Login' });
    });
  },
  async refreshToken({ commit, getters, state }) {
    let encodedRefreshToken = localStorage.getItem('refreshToken') || '';
    let refreshTokenInfo = {};
    let tmpString = '';
    if (encodedRefreshToken) {
      refreshTokenInfo = JSON.parse(atob(encodedRefreshToken.slice(10)));
      tmpString = generateRandomString(10) + btoa(Object.values(refreshTokenInfo).join());
    } else return Promise.reject();
    await http.post('/token', {
      grantType: "client_refresh_token",
      refreshToken: tmpString
    }, { notRefresh: true }).then((response) => {
      const { accessToken, passwordExpiresAt, refreshToken } = response.data;
      refreshTokenInfo.refreshToken = refreshToken;
      commit('SET_PASSWORD_EXPIRES_AT', passwordExpiresAt);
      commit('SET_TOKEN', accessToken);
      commit('SET_REFRESH_TOKEN', JSON.stringify(refreshTokenInfo))
      if (passwordExpiresAt && !getters.isPasswordExpired) {
        setTokenStorage(state);
      }
    });
  }
};
