import permission from '@/helpers/permission';
import store  from '@/store';
import { RouterConstants } from '@/constants/router';

const redirectDefaultRouter = (next) => {
  const userRole = store.getters['auth/userRole'];
  const isGmoOrOfficeLoginStore = store.getters['common/isGmoOrOfficeLoginStore'];
  if (userRole) {
    if(permission.isGmoOrOffice() && !isGmoOrOfficeLoginStore) {
      next({ name: RouterConstants.DEFAULT_ROUTER_OF_MASTER_OR_CLIENT });
    }
    if(permission.isStoreOrGroup() || isGmoOrOfficeLoginStore) {
      next({ name: RouterConstants.DEFAULT_ROUTER_OF_SHOP_OR_GROUP });
    }
  }
}

export default redirectDefaultRouter;
