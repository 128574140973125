export default {
  SET_NEWS_LIST(state, payload) {
    state.newsList = {
      list: payload.list,
      count: payload.count,
    };
  },
  SET_NEWS_DETAIL(state, newsDetail) {
    state.newsDetail = { ...state.newsDetail, ...newsDetail };
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
};
