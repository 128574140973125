import http from '@/config/api';
import router from '@/router';
import permission from '@/helpers/permission';
import { StatusConstants } from '@/constants/status';

export default {
  async getNewsList({ commit, rootGetters }, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    const directory = params.directory || router.currentRoute.params.directory;
    const type = (permission.isGmoOrOffice() && !rootGetters['common/isGmoOrOfficeLoginStore']) ? StatusConstants.topic.office : StatusConstants.topic.shop;
    await http.get(`/event/${subdomain}/child/${directory}/topics`, {
      params: {
        type,
        ...params,
      }
    }).then((response) => {
      if (response) commit('SET_NEWS_LIST', response.data);
    })
  },
  createNews(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    const directory = payload.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/topic`, payload);
  },
  async getNewsDetail({ commit }, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    const directory = params.directory || router.currentRoute.params.directory;
    await http.get(`/event/${subdomain}/child/${directory}/topic/${params.id}`).then((response) => {
      if (response) commit('SET_NEWS_DETAIL', response.data);
    })
  },
  updateNews(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    const directory = payload.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/topic/${payload.id}`, payload);
  },
  deleteNews(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    const directory = payload.directory || router.currentRoute.params.directory;
    return http.delete(`/event/${subdomain}/child/${directory}/topic/${payload.id}`);
  },
  updateNewsStatus({ commit }, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    const directory = payload.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/topic/${payload.id}/status`, payload)
      .then((response) => {
        if (response) commit('SET_NEWS_DETAIL', { validFlag: payload.status });
        return response?.data?.id;
      });
  }
}
