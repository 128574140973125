export default {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  SET_PASSWORD_EXPIRES_AT(state, passwordExpiresAt) {
    state.passwordExpiresAt = passwordExpiresAt;
  },
  SET_AUTH_EMAIL(state, authEmailFlag) {
    state.authEmailFlag = authEmailFlag;
  },
  SET_INFOR(state, infor) {
    state.infor = infor;
  },
  IS_BLOCK_AUTHEN(state, isBlockAuthen) {
    state.isBlockAuthen = isBlockAuthen;
  },
  RESET_LOCAL_STORAGE() {
    const subdomain = localStorage.getItem('SUBDOMAIN');
    const isOldEvent = localStorage.getItem('isOldEvent');
    localStorage.clear();
    if (isOldEvent) {
      localStorage.setItem('SUBDOMAIN', 'management');
    } else if (subdomain) {
      localStorage.setItem('SUBDOMAIN', subdomain);
    }
  }
};
