import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialClient = () => ({
  clientList: [],
})

export default {
  state: initialClient(),
  mutations,
  actions,
  getters,
  namespaced: true
};
