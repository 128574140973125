import { format } from 'date-fns';
import { CommonConstants } from '@/constants/common'

function getShortDate(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATE_FORMAT);
}

function getCustomShortDate(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.CUSTOM_DATE_FORMAT);
}

function getFullDate(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.DATETIME_FORMAT);
}

function getFullDateTime(value) {
  if (!value) return;
  return format(new Date(value), CommonConstants.SPECIFIC_DATETIME_FORMAT);
}

function getFirstDateThisMonth() {
  return format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd')
}

function getLastDateThisMonth() {
  return format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd')
}

export {
  getShortDate,
  getFullDate,
  getFullDateTime,
  getFirstDateThisMonth,
  getLastDateThisMonth,
  getCustomShortDate
}
