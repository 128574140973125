export default {
  customerList: (state) => state.customerList.list,
  customerCount: (state) => state.customerList.count,
  customerTotal: (state) => state.customerTotal,
  customerCpmTotal: (state) => state.customerCpmTotal,
  customerActivities: (state) => state.customerActivities,
  customerDetail: (state) => state.customerDetail,
  totalUnsubscribe: (state) => state.totalUnsubscribe,
  hasCustomerTotal: (state) => state.hasCustomerTotal,
  templatesList: (state) => state.templatesList.list,
  templatesCount: (state) => state.templatesList.count,
  newsList: (state) => state.newsList.list,
  newsCount: (state) => state.newsList.count,
  templateDetail: (state) => state.templateDetail,
  newsDetail: (state) => state.newsDetail,
  childList: (state) => state.childList.list,
  existsOmiseNumber: (state) => state.existsOmiseNumber,
};
