import http from '@/config/api';
import { addressApi } from '@/config/addressApi';

export default {
  setLoading({ commit }, isLoading) {
    return commit('SET_LOADING', isLoading);
  },
  setButtonLoading({ commit }, isButtonLoading) {
    return commit('SET_BUTTON_LOADING', isButtonLoading);
  },
  openErrorModal({ commit }, isErrorModalOpened) {
    return commit('OPEN_ERROR_MODAL', isErrorModalOpened);
  },
  setErrors({ commit }, errors) {
    return commit('SET_ERRORS', errors);
  },
  setErrorFields({ commit }, errorFields) {
    return commit('SET_ERROR_FIELDS', errorFields);
  },
  resetErrorFields({ commit }) {
    return commit('RESET_ERROR_FIELDS');
  },
  async getCardTemplateList({ commit }) {
    await http.get('/data/cardImageTemplates').then((response) => {
      if (response) commit('SET_CARD_TEMPLATE_LIST', response.data.list);
    });
  },
  uploadImage({ commit }, payload) {
    return http.post('/image', payload, { notLoading: true })
      .then((response) => {
        if (response) return response.data.id;
        else {
          commit("SET_UPLOAD_ERRORS", payload);
        }
      })
  },
  uploadSound({ commit }, payload) {
    return http.post('/sound', payload, { notLoading: true })
      .then((response) => {
        if (response) return response.data;
        else {
          commit("SET_UPLOAD_ERRORS", payload);
        }
      })
  },
  async getChargeAmountChoices({ commit }) {
    await http.get('/data/chargeChoices').then((response) => {
      if (response) commit('SET_CHARGE_AMOUNT_CHOICES', response.data.list);
    });
  },
  async getGenres({ commit }) {
    await http.get('/data/genres').then((response) => {
      if (response) commit('SET_GENRES', response.data.list)
    });
  },
  async getPaymentCycles({ commit }) {
    await http.get('/data/paymentCycles').then((response) => {
      if (response) commit('SET_PAYMENT_CYCLES', response.data.list);
    });
  },
  getPrefectures({ commit }) {
    return http
      .get('/prefectures', addressApi)
      .then((res) => {
        if (res) commit('SET_PREFECTURES', res.data);
      });
  },
  getMunicipalities({ commit }, prefectureId) {
    return http
      .get(`/municipalities/${prefectureId}`, addressApi)
      .then((res) => {
        if (res) commit('SET_MUNICIPALITIES', res.data);
      });
  },
  getAddressFromZipcode(_, zipcode) {
    return http
      .get(`/zipcode/${zipcode}`, addressApi)
      .then((res) => res?.data);
  },
  resetMunicipalities({ commit }) {
    commit('SET_MUNICIPALITIES', []);
  },
  async getTopicTemplateList({ commit }) {
    await http.get('/data/topicImageTemplates').then((response) => {
      if (response) commit('SET_TOPIC_TEMPLATE_LIST', response.data.list);
    });
  },
  async getBackgroundTemplateList({ commit }) {
    await http.get('data/backgroundTemplates').then((response) => {
      if (response) commit('SET_BACKGROUND_TEMPLATE_LIST', response.data.list);
    });
  },
  async getUserPrivileges({ commit }) {
    await http.get('data/roles').then((response) => {
      if (response) commit('SET_USER_PRIVILEGE_LIST', response.data.list);
    });
  },
  async getConvenienceStores({ commit }) {
    await http.get('/data/convenienceStores').then((response) => {
      if (response) commit('SET_CONVENIENCE_STORES', response.data);
    });
  },
  async getStatusExportCSV(_, id) {
    return await http.post(`export/status/${id}`,_, {
      notLoading: true,
    }).then((response) => response?.data);
  },
  getImportStatus(_, id) {
    return http.get(`import/manual/${id}/status`).then((response) => {
      return response?.data?.result;
    });
  },
};
