import http from '@/config/api';
import router from '@/router';

export default {
  async getAttendStores({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return await http
      .get(`/event/${subdomain}/child/${directory}/shops`, { params })
      .then((response) => {
        if (response) {
          commit('SET_ATTEND_STORE_LIST', response.data.list);
          commit('SET_ATTEND_STORE_COUNT', response.data.count);
        }
      });
  },
  async getAttendStoreTotal({ commit }, params) {
    const directory = params?.directory || router.currentRoute.params.directory;
    await http.get(`/event/${params.subdomain}/child/${directory}/shops`, { params }).then(response => {
      if (response)
        commit('SET_ATTEND_STORE_TOTAL', response.data.count);
    });
  },
  async getAttendStoreCSV({ state }, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return await http
      .post(`event/${subdomain}/child/${directory}/shops/export`, {
        ...params,
        orderBy: state.sortText,
        sortOrder: state.sortType,
      })
      .then((response) => response?.data);
  },
  async getAttendStoreGroup({ commit }, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    await http
      .get(`event/${subdomain}/child/${directory}/event-group`, { params })
      .then((response) => {
        if (response) {
          commit('SET_ATTEND_STORE_GROUP', response.data);
        }
      });
  },
  importAttendShopCsv(_, form) {
    const subdomain = form?.subdomain || router.currentRoute.params.subdomain;
    const directory = form?.directory || router.currentRoute.params.directory;
    return http.post(`event/${subdomain}/child/${directory}/shops/import`, form, { isMultipartContent: true });
  },
  async registerShop(_, form) {
    form = {
      ...form,
      companyName: form.company,
      categories: form.categories.map((item) => item.id),
    };
    const subdomain = router.currentRoute.params.subdomain;
    const directory = router.currentRoute.params.directory;
    return await http.post(`event/${subdomain}/child/${directory}/shops`, form);
  },
};
