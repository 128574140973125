import mutations from './mutation';
import actions from './actions';
import getters from './getters';

export const initialAttendStore = () => ({
  attendStoreList: [],
  attendStoreTotal: 0,
  attendStoreCount: 0,
  attendStoreGroup: {},
  sortText: '',
  sortType: '',
});

export default {
  state: initialAttendStore(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
