<template>
  <div id="app">
    <transition name="fade">
      <Loading v-if="isLoading"></Loading>
    </transition>
    <Side v-if="isLoggedIn && isPathWithAuth && !isSystemError"/>
    <div v-if="isSystemError" class="systemErrorWrapper">
      <router-view />
    </div>
    <div v-else-if="isLoggedIn && isPathWithAuth" class="wrapper">
      <div v-if="isNearExpiredPassword" class="password-alert">
        <i class="aikon aikon-attention password-alert-icon"></i>
        <div class="password-alert-content">
          <p class="password-alert-text">アカウントパスワードの有効期限：{{ expiredDate }}まで</p>
          <router-link class="btn password-alert-btn" :to="{ name: 'PasswordReset' }">パスワードを更新する</router-link>
        </div>
      </div>
      <router-view />
    </div>
    <div v-else-if="!isLoggedIn || !isPathWithAuth" class="login">
      <router-view />
    </div>
    <Alert />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading';
import Alert from '@/components/Alert.vue';
import Side from '@/components/Side.vue';
import { formatJapaneseDate } from '@/helpers/formatData';

export default {
  name: 'App',
  data: function() {
    return {
      pagePath: null,
      passwordLimit: false,
      //login
      passwordDefault: true,
      //copyright
      copyright: 'Copyright © 2021 GMO DIGITAL Lab K.K. All Rights Reserved.',
      isPathWithAuth: null,
    };
  },
  components: {
    Loading,
    Alert,
    Side,
  },
  computed: {
     ...mapGetters({
      passwordExpiresAt: 'auth/passwordExpiresAt',
      isLoggedIn: 'auth/token',
      isLoading: 'common/isLoading',
      isNearExpiredPassword: 'auth/isNearExpiredPassword',
    }),
    isSystemError() {
      return this.$route.name === '500';
    },
    expiredDate() {
      return formatJapaneseDate(this.passwordExpiresAt)
    }
  },
  methods: {
    passwordUpdate: function() {
      this.$router.push({ name: 'PasswordReset' });
    },
    checkPathWithAuth: function() {
      this.isPathWithAuth = !['/login', '/password/default', '/password/reset', '/password/code'].includes(location.pathname);
    },
  },
  created() {
    this.checkPathWithAuth();
  },
  watch: {
    $route: function() {
      this.checkPathWithAuth();
    },
  },
};
</script>
