<template>
  <!-- <div class="alert" v-if="isShow > 0"> -->
  <div class="alert">
    <AlertContent
      v-for="alert in getAlerts"
      :key="alert.id"
      @remove="removeAlert(alert.id)"
      :message="alert.message"
      :type="alert.type"
      :autoclose="alert.autoclose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertContent from '@/components/AlertContent.vue';

export default {
  components: { AlertContent },
  methods: {
    removeAlert(i) {
      this.$store.dispatch('REMOVE_ALERT', i);
    },
  },
  // computed: {
  //   ...mapGetters(['getAlerts', 'isShow']),
  // },
  computed: {
    ...mapGetters(['getAlerts']),
  },
  watch: {
    $route: function(to, from) {
      if (to.path !== from.path) {
        this.$store.dispatch('DELETE_ALERT');
      }
    },
  },
};
</script>
