<template>
  <!--[pageFooter]-->
  <div class="pageFooter" v-if="this.$slots.footer">
    <slot name="footer"></slot>
  </div>
  <!--[/pageFooter]-->
</template>

<script>
export default {
  name: 'PageFooter',
  // data: function() {
  //   return {};
  // },
  props: {
    pageName: String,
    pageNavs: Array,
  },
  computed: {
    navPoint: function() {
      return this.$route.fullPath;
    },
  },
};
</script>
