<template>
  <!--[pageHeader]-->
  <header class="pageHeader">
    <div class="pageHeader-headline">
      <h2 class="headline headline-lg pageHeader-headline-text">{{ pageName }}</h2>
    </div>
    <div class="pageHeader-button" v-if="isSettingShown">
      <button type="button" class="btn btn-sm btn-bd-main" @click="handleClick">表示設定</button>
    </div>
    <nav class="scrollX pageHeader-nav">
      <ul class="pageHeader-nav-set">
        <li class="pageHeader-nav-item" v-for="pageNav in pageNavs" :key="pageNav.id">
          <router-link
            v-if="checkPolicy(pageNav.routeName) && (pageNav.eventFlag ? isOfficeHadEvent : true)"
            class="pageHeader-nav-link"
            :to="{ name: pageNav.routeName }"
            :class="{ 'is-active': pageNav.includes ? navPointName.includes(pageNav.routeName) : navPointName === pageNav.routeName }"
            >{{ pageNav.name }}</router-link
          >
        </li>
      </ul>
    </nav>
  </header>
  <!--[/pageHeader]-->
</template>

<script>
import { mapGetters } from 'vuex';
import { RoleConstants } from '@/constants/role';

export default {
  name: 'PageHeader',
  props: {
    pageName: String,
    pageNavs: Array,
    handleClick: {
      type: Function,
      required: false
    },
    isSettingShown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      isOfficeHadEvent: 'auth/isOfficeHadEvent',
      isClientChild: 'auth/isClientChild'
    }),
    navPointName: function() {
      return this.$route.name;
    },
  },
  methods: {
    checkPolicy(routeName) {
      if (this.infor.kind === RoleConstants.ROLE_OFFICE) {
        if ((!this.infor.role.includes(RoleConstants.ROLE_EVENTS_OFFICE) && routeName == 'SettingEvent') ||
        (this.isClientChild && routeName === 'EventSummaryCompany')) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
