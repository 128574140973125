import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialGallery = () => ({
  categoryList: { list: [] },
  galleryList: { list: [] },
  galleryDetail: {},
});

export default {
  state: initialGallery(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
