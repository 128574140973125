export default {
  SET_HISTORY_CHARGES(state, payload) {
    return (state.historyCharges = payload);
  },
  SET_HISTORY_CHARGES_COUNT(state, payload) {
    return (state.historyChargesCount = payload);
  },
  SET_HISTORY_CHARGE_DETAIL(state, payload) {
    return (state.historyChargeDetail = payload);
  },
  SET_HISTORY_PAYMENTS(state, payload) {
    return (state.historyPayments = payload);
  },
  SET_HISTORY_PAYMENTS_COUNT(state, payload) {
    return (state.historyPaymentsCount = payload);
  },
  SET_HISTORY_PAYMENT_DETAIL(state, payload) {
    return (state.historyPaymentDetail = payload);
  },
  SET_HISTORY_PAYMENTS_TOTAL(state, payload) {
    return (state.historyPaymentTotal = payload);
  },
  SET_HISTORY_PAYMENTS_BALANCE(state, payload) {
    return (state.historyPaymentBalance = payload);
  },
  SET_IS_HISTORY_PAYMENTS_NOT_SEEN(state, payload) {
    state.isHistoryPaymentNotSeen = payload;
  },
  RESET_HISTORY_PAYMENTS(state) {
    state.historyPayments = [];
    state.historyPaymentsCount = 0;
  },
  RESET_HISTORY_PAYMENT_DETAIL(state) {
    state.historyPaymentDetail = {};
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
  SET_TICKET_LIST(state, payload) {
    state.ticketList = payload;
  },
  SET_METHOD_LIST(state, payload) {
    state.methodList = payload;
  },
  SET_HISTORY_TOTAL_ADDED(state, payload) {
    state.totalAdded = payload;
  },
};
