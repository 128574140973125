import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { getToken } from '@/helpers/http';

export const initialAuth = () => ({
  token: getToken(),
  refreshToken: null,
  passwordExpiresAt: null,
  authEmailFlag: 0,
  infor: {},
  userChildRoles: [],
  isBlockAuthen: false
});

export default {
  state: initialAuth(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
