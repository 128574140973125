import http from '@/config/api';
import router from '@/router';
import { getSubdomainAndDirectory } from '@/helpers/common';

export default {
  async getParentEventAggregate({ commit, rootGetters }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain || rootGetters['common/subdomain'];
    await http.get(`/event/${subdomain}/aggregate`).then((response) => {
      if (response) commit('SET_PARENT_EVENT_AGGREGATE', response.data);
    });
  },
  async getChildEventAggregate({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate`, { params }).then((response) => {
      if (response) commit('SET_CHILD_EVENT_AGGREGATE', response.data);
    });
  },
  async getCompanyChildEventAggregate({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/company`, { params }).then((response) => {
      if (response) commit('SET_COMPANY_CHILD_EVENT_AGGREGATE', response.data);
    });
  },
  async getCompanyChildEventAggregateTicket({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/company/ticket`, { params }).then((response) => {
      if (response) commit('SET_COMPANY_CHILD_EVENT_AGGREGATE', response.data);
    });
  },
  async getCompanyChildEventAggregateTotal({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/company`, { params }).then((response) => {
      if (response) commit('SET_COMPANY_CHILD_EVENT_AGGREGATE_TOTAL', response.data.total);
    });
  },
  async getCompanyChildEventAggregateTotalTicket({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/company/ticket`, { params }).then((response) => {
      if (response) commit('SET_COMPANY_CHILD_EVENT_AGGREGATE_TOTAL', response.data.total);
    });
  },
  async getShopAggregate({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/shops`, { params }).then((response) => {
      if (response) commit('SET_SHOP_AGGREGATE', response.data);
    })
  },
  async getShopAggregateTicket({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/shops/ticket`, { params }).then((response) => {
      if (response) commit('SET_SHOP_AGGREGATE', response.data);
    })
  },
  async getPaymentAggregate({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    const interval = params?.interval ? '/' + params.interval : '';
    const startInterval = params?.startInterval;
    await http.get(`/event/${subdomain}/child/${directory}/aggregate/payment${interval}`, { params: { startInterval, ...params }}).then((response) => {
      if (response) {
        const { list, total, previous } = response.data;
        if (params?.type === 'amount') {
          commit('SET_PAYMENT_AMOUNT', { list, total, interval: params.interval, startInterval });
          commit('SET_PREVIOUS_PAYMENT_AMOUNT', previous);
        } else if (params?.type === 'count'){
          commit('SET_PAYMENT_COUNT', { list, total, interval: params.interval, startInterval });
          commit('SET_PREVIOUS_PAYMENT_COUNT', previous);
        } else {
          commit('SET_PAYMENT_TOTAL', total);
        }
      }
    })
  },
  async downloadShopAggregateList({ state }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.post(`/event/${subdomain}/child/${directory}/aggregate/shops/export`, {
      ...params,
      orderBy: state.sortText,
      sortOrder: state.sortType,
    }).then((response) => response?.data)
  },
  async downloadShopAggregateListTicket({ state }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.post(`/event/${subdomain}/child/${directory}/aggregate/shops/ticket/export`, {
      ...params,
      orderBy: state.sortText,
      sortOrder: state.sortType,
    }).then((response) => response?.data)
  },
  async downloadCompanyAggregateList(_, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.post(`/event/${subdomain}/child/${directory}/aggregate/companies/export`, {
      ...params
    }).then((response) => response?.data)
  },
  async downloadCompanyAggregateListTicket(_, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.post(`/event/${subdomain}/child/${directory}/aggregate/company/ticket/export`, {
      ...params
    }).then((response) => response?.data)
  },
}
