import http from '@/config/api';
import router from '@/router';

export default {
  createUser(_, payload) {
    return http.post('user', payload.data, { params: { kind: payload.kind } });
  },
  getUsers({ commit }, params) {
    return http.get('users', { params }).then((response) => {
      if (response) {
        commit('SET_USER_LIST', response.data.list);
        commit('SET_USER_COUNT_SEARCH', response.data.count);
      }
    });
  },
  getUserCount({ commit }, params) {
    return http.get('users', { params: { ...params, limit: 1 }}).then((response) => {
      if (response) commit('SET_USER_COUNT', response.data.count);
    });
  },
  async getUserDetail(context, id) {
    const res = await http.get(`user/${id}`).then(
      (res) => res.data,
      () => null
    );
    if (!res) return;
    if (res.kind === '1') {
      const newRes = { ...res, password: '', companyId: null };
      context.commit('SET_USER_DETAIL', newRes);
    } else {
      context.commit('SET_USER_DETAIL', { ...res, password: '' });
    }
  },
  updateUser(_, payload) {
    return http.post(`user/${payload.id}`, payload.data, { params: { kind: payload.kind } });
  },
  deleteUser(_, id) {
    return http.delete(`user/${id}`).then((response) => response);
  },
  updateStatus(_, payload) {
    const id = router.currentRoute.params.id;
    return http.post(`user/${id}/status`, payload);
  },
  async getUserActivities({ commit }, params) {
    await http.get(`/event/user/${params.id}/activities`, { params })
      .then(response => {
        if (response) commit('SET_USER_ACTIVITIES', response.data.list);
      })
  },
};
