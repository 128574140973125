export default {
  SET_ATTEND_STORE_LIST(state, payload) {
    return (state.attendStoreList = payload);
  },
  SET_ATTEND_STORE_TOTAL(state, payload) {
    return (state.attendStoreTotal = payload);
  },
  SET_ATTEND_STORE_COUNT(state, payload) {
    return (state.attendStoreCount = payload);
  },
  SET_ATTEND_STORE_GROUP(state, payload) {
    return (state.attendStoreGroup = payload);
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
};
