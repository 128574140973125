import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialGroup = () => ({
  groupList: {
    list: [],
    count: 0,
  },
  sortText: '',
  sortType: '',
});

export default {
  state: initialGroup(),
  mutations,
  actions,
  getters,
  namespaced: true
};
