import http from '@/config/api';
import router from '@/router';

export default {
  getCpmLoginLogs({ commit }, params) {
    return http.get(`event/${params.subdomain}/shop/${params.shopId}/cpm-login-logs`, { params })
      .then((response) => {
        if (response) {
          commit('SET_CPM_LOGIN_LOG_LIST', response.data.list);
          commit('SET_CPM_LOGIN_LOG_COUNT', response.data.count);
        }
      });
  },
  getCpmUsers({ commit }, params) {
    return http.get(`event/${params.subdomain}/shop/${params.shopId}/cpm-users`)
      .then((response) => {
        if (response) {
          commit('SET_USER_CPM_LIST', response.data.list);
          commit('SET_USER_CPM_COUNT', response.data.users);
        }
      });
  },
  async getCpmUserDetail({ commit }, params) {
    const id = router.currentRoute.params.id;
    const res = await http.get(`event/${params.subdomain}/shop/${params.shopId}/cpm-user/${id}`).then(
      (res) => res.data,
      () => null
    );
    if (!res) return;
    commit('SET_USER_CPM_DETAIL', res);
  },
  createCpmUser(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/cpm-user`, payload.data);
  },
  updateCpmUser(_, payload) {
    const id = router.currentRoute.params.id;
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/cpm-user/${id}`, payload.data);
  },
  deleteCpmUser(_, payload) {
    const id = router.currentRoute.params.id;
    return http.delete(`event/${payload.subdomain}/shop/${payload.shopId}/cpm-user/${id}`, payload.data);
  },
  deleteCpmUsers(_, payload) {
    return http.delete(`event/${payload.subdomain}/shop/${payload.shopId}/cpm-users`, {
      data: payload.data,
    });
  },
  updateStatus(_, payload) {
    const id = router.currentRoute.params.id;
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/cpm-user/${id}/status`, payload);
  },
};
