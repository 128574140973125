import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

//validation
//https://github.com/vuelidate/vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

//add commma
//https://qiita.com/msht0511/items/f285383dc28bd3666bfe
//http://www.sky-limit-future.com/entry/vuefilter_addcomma
Vue.filter('comma', function(val) {
  return Number(val).toLocaleString();
});
//toUpperCase
Vue.filter('upperCase', function(val) {
  return val.toUpperCase();
});
//unroundedComma
Vue.filter('unroundedComma', function(val) {
  return isNaN(val) ? val : val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});
import { getShortDate, getFullDate, getFullDateTime, getCustomShortDate } from './filters/date';
Vue.filter('shortDate', getShortDate);
Vue.filter('fullDate', getFullDate);
Vue.filter('fullDateTime', getFullDateTime);
Vue.filter('customShortDate', getCustomShortDate);

//global directives
import TrimDirective from './directives/trim';
import NumberDirective from './directives/number';
import MaxLenghtDirective from './directives/maxlength';
import KatakanaDirective from './directives/katakana';
import AlphabetDirective from './directives/alphabet';
import SpaceDirective from './directives/space';
import DecimalDirective from './directives/decimal';
import EmailDirective from './directives/email';
import HiraganaDirective from './directives/hiragana';
import AlphabetAndNumericHalfsizeDirective from './directives/alphabetAndNumericHalfsize';
import AlphaNumSpecialHalfsizeDirective from './directives/alphaNumSpecialHalfsize';
import NumberAndCommaDirective from './directives/numberAndComma';

Vue.directive('trim', TrimDirective);
Vue.directive('number', NumberDirective);
Vue.directive('maxlength', MaxLenghtDirective);
Vue.directive('katakana', KatakanaDirective);
Vue.directive('alphabet', AlphabetDirective);
Vue.directive('space', SpaceDirective);
Vue.directive('decimal', DecimalDirective);
Vue.directive('email', EmailDirective);
Vue.directive('hiragana', HiraganaDirective);
Vue.directive('alphabetAndNumericHalfsize', AlphabetAndNumericHalfsizeDirective);
Vue.directive('alphaNumSpecialHalfsize', AlphaNumSpecialHalfsizeDirective);
Vue.directive('numberAndComma', NumberAndCommaDirective);

//pageHeader
import PageHeader from './components/PageHeader.vue';
Vue.component('PageHeader', PageHeader);
//pageFooter
import PageFooter from './components/PageFooter.vue';
Vue.component('PageFooter', PageFooter);
//panel
import Panel from './components/Panel.vue';
Vue.component('Panel', Panel);
//action button
import ActionButton from './components/ActionButton.vue';
Vue.component('ActionButton', ActionButton);

import permission from '@/helpers/permission';
import message from '@/helpers/message';
import loading from '@/helpers/loading';
Vue.prototype.$permission = permission;
Vue.prototype.$message = message;
Vue.prototype.$loading = loading;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
