import uuidv4 from 'uuid/v4';

const state = {
  alerts: [],
};

const getters = {
  getAlerts: (state) => state.alerts,
};

const actions = {
  ADD_ALERTS({ commit }, payload) {
    commit('addAlerts', payload);
  },
  REMOVE_ALERT({ commit }, payload) {
    commit('removeAlert', payload);
  },
  DELETE_ALERT({ commit }) {
    commit('deleteAlert');
  },
};

const mutations = {
  addAlerts(state, payload) {
    state.alerts = [...state.alerts, { ...payload.alerts, id: uuidv4(), message: payload.message, type: payload.type, autoclose: payload.autoclose }];
  },
  removeAlert(state, payload) {
    state.alerts.forEach((v, index) => {
      if (v.id === payload) {
        state.alerts.splice(index, 1);
      }
    });
  },
  deleteAlert(state) {
    state.alerts.splice(0);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
