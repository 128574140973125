export class RoleConstants {
  static ROLE_GMO = '9';
  static ROLE_OFFICE = '1';
  static ROLE_STORE = '3';
  static ROLE_GROUP = '4';
  static ROLE_CLIENT_PARENT = '1.1';
  static ROLE_CLIENT_CHILD = '1.2';
  static ROLE_GMO_OR_OFFICE = [this.ROLE_GMO, this.ROLE_OFFICE];
  static ROLE_STORE_OR_OFFICE = [this.ROLE_STORE, this.ROLE_OFFICE];
  static ROLE_STORE_OR_GROUP = [this.ROLE_STORE, this.ROLE_GROUP];
  static ROLE_GMO_OR_OFFICE_OR_STORE = [this.ROLE_GMO, this.ROLE_OFFICE, this.ROLE_STORE];
  static ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP = [this.ROLE_GMO, this.ROLE_OFFICE, this.ROLE_STORE, this.ROLE_GROUP];
  static ROLE_EVENTS_OFFICE = 'events';
  static ROLE_CUSTOMERS_OFFICE = 'customers';
  static ROLE_SHOPS_OFFICE = 'shops';
  static ROLE_TRANSACTIONS_OFFICE = 'transaction';
  static ROLE_AGGREGATES_OFFICE = 'aggregate';
  static ROLE_CURRENCY_OFFICE = 'currency';
  static ROLE_USERS_OFFICE = 'users';
  static ROLE_COUPONS_OFFICE = 'coupons';
  static ROLE_MANAGE_SHOPS_OFFICE = 'manage_shop';
  static ROLE_MANAGE_CURRENCY_OFFICE = 'manage_currency';
  static ROLE_MANAGE_QR_OFFICE = 'manage_qr';
  static ROLE_POINTS_OFFICE = 'points';
}
