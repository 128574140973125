import { CommonConstants } from '@/constants/common'

export default {
  bind(el) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const inputEvent = isSafari ? 'keydown' : 'input';
    el.addEventListener(
      inputEvent,
      (e) => {
        e.target.value = e.target.value.replace(CommonConstants.SPACE_REGEX, '');
      },
      true
    );
  },
};
