import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialNews = () => ({
  newsList: {
    list: [],
    count: 0,
  },
  newsDetail: {},
  sortText: '',
  sortType: '',
});

export default {
  state: initialNews(),
  mutations,
  actions,
  getters,
  namespaced: true
};
