import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialCurrency = () => ({
  categoriesList: {
    list: [],
    count: 0
  },
  qrTicketList: {
    list: [],
    count: 0
  },
  releaseOtpList: {
    list: [],
    count: 0
  },
  otpList: {
    list: [],
    count: 0,
    inactiveCount: 0,
  },
  allQrTicketList: {
    list: [],
    count: 0
  },
  ticketQrDetail: {},
  qrCodeTickets: {
    list: [],
    count: 0
  },
  issuerQrCodes: {
    list: [],
  },
  chargeTickets: {
    list: [],
  },
  paymentTickets: {
    list: [],
  },
  amountStoreList: [],
});

export default {
  state: initialCurrency(),
  mutations,
  actions,
  getters,
  namespaced: true
};
