export default {
  shopList: (state) => state.shopList.list,
  shopCount: (state) => state.shopList.count,
  shopTotal: (state) => state.shopTotal,
  shopDetail: (state) => state.shopDetail,
  validFlag: (state) => state.shopDetail?.validFlag,
  approvedShopTotal: (state) => state.approvedShopTotal,
  requestedShopTotal: (state) => state.requestedShopTotal,
  sortText: (state) => state.sortText,
  sortType: (state) => state.sortType,
  config: (state) => state.config,
  newShopId: (state) => state.newShopId,
};
