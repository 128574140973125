export default {
  SET_CPM_LOGIN_LOG_LIST(state, payload) {
    return (state.cpmLoginLogList = payload);
  },
  SET_CPM_LOGIN_LOG_COUNT(state, payload) {
    return (state.cpmLoginLogCount = payload);
  },
  SET_USER_CPM_LIST(state, payload) {
    return (state.cpmUserList = payload);
  },
  SET_USER_CPM_COUNT(state, payload) {
    return (state.cpmUserCount = payload);
  },
  SET_USER_CPM_DETAIL(state, payload) {
    return (state.cpmUserDetail = payload);
  }
};
