export default {
  SET_GROUP_LIST(state, payload) {
    state.groupList = {
      list: payload.list,
      count: payload.count,
    };
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
};
