export default {
  SET_USER_LIST(state, payload) {
    return (state.userList = payload);
  },
  SET_USER_DETAIL(state, payload) {
    return (state.userDetail = payload);
  },
  SET_USER_COUNT(state, payload) {
    return (state.userCount = payload);
  },
  SET_USER_COUNT_SEARCH(state, payload) {
    return (state.userCountSearch = payload);
  },
  SET_USER_ACTIVITIES(state, list) {
    state.userActivities = list;
  },
};
