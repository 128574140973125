import permission from '@/helpers/permission';

export default {
  parentEventList: (state) => state.parentEventList.list,
  parentEventCount: (state) => state.parentEventList.count,
  bankList: (state) => state.bankList.list,
  bankCount: (state) => state.bankList.count,
  existIdentity: (state) => state.parentEventList.existData?.existIdentity,
  existOmise: (state) => state.parentEventList.existData?.existOmise,
  existVCN: (state) => state.parentEventList.existData?.existVCN,
  existSms: (state) => state.parentEventList.existData?.existSms,
  childEventList: (state) => state.childEventList.list,
  childEventCount: (state) => state.childEventList.count,
  parentEventDetail: (state) => state.parentEventDetail,
  childEventDetail: (state) => state.childEventDetail,
  childEventType: (state) => state.childEventType,
  eventGroupList: (state) => state.eventGroupList.list,
  newEvent: (state) => state.newEvent,
  eventType: (state) => state.eventType,
  errorFields: (state) => state.errorFields,
  eventCategories: (state) => state.parentEventDetail.categories || [],
  eventGroups: (state) => state.childEventDetail.groups || [],
  eventPaymentCycles: (state) => state.childEventDetail.paymentCycles || [],
  shopGroups: (state) => state.childEventDetail.shopGroups || [],
  shopUrl: (state) => state.parentEventDetail?.shopUrl || null,
  useTicketFlag: (state) => !!state.parentEventDetail.useTicketFlag,
  useTicketFlagNumber: (state) => state.parentEventDetail.useTicketFlag,
  hasHistoryTicketCompany: (state) => !!state.childEventDetail?.hasHistoryTicketCompany,
  isOldEvent: (state) => state.parentEventDetail?.isOldEvent,
  customerNoJoin: (state) => state.childEventDetail?.customerNoJoin || 0,
  omiseLinked: (state) => !!state.parentEventDetail?.omiseLinked,
  useOmise: (state) => !!state.parentEventDetail?.useOmise,
  identityVerification: (state) => !!state.parentEventDetail?.identityVerification,
  useSms: (state) => !!state.parentEventDetail?.useSms,
  hasManualCharge: (state) => state.childEventDetail?.hasManualCharge,
  viewAllhistory: (state) => !!state.childEventDetail?.viewAllhistory,
  isShowCustomer: (_, getters, rootState, rootGetters) => {
    if (permission.isStoreOrGroup()) {
      return getters.parentEventDetail?.showCustomerFlag;
    } else if (rootGetters['common/isGmoOrOfficeLoginStore']) {
      return rootGetters['auth/hasCustomersRole'];
    }
    return true;
  },
  isShowMenuCoupon: (_, getters) => {
    if (permission.isStoreOrGroup()) {
      return getters.parentEventDetail?.showMenuCoupon;
    }
    return true;
  },
  getEventBySubdomain: (state) => (subdomain) => {
    return state.eventList.list.find(event => event.subdomain === subdomain);
  },
  isShowCurrency: (_, getters) => {
    if (permission.isGmoOrOffice()) {
      return getters.childEventDetail?.useCurrencyFlag;
    }
    return false;
  },
  isUseCurrencyFlagParentEvent: (_, getters) => {
    return getters.parentEventDetail?.useCurrencyFlag;
  },
  hasCurrency: (_, getters) => {
    return getters.childEventDetail?.useCurrencyFlag;
  },
  isUseCpmParentEvent: (_, getters) => {
    return getters.parentEventDetail?.useCpm;
  },
  isUsePointFlagParentEvent: (_, getters) => {
    return getters.parentEventDetail?.usePointFlag;
  },
  isCommonAllEvent: (_, getters) => {
    return getters.parentEventDetail?.typePoint === 1;
  },
  isOnlyChildEvent: (_, getters) => {
    return getters.parentEventDetail?.typePoint === 2;
  },
  isUsePointFlagChildEvent: (_, getters) => {
    return getters.childEventDetail?.usePointFlag;
  },
  isUseCpmChildEvent: (_, getters) => {
    return getters.childEventDetail?.useCpm;
  },
  canUseCpmParentEvent: (_, getters) => {
    return getters.parentEventDetail?.canUseCPM;
  },
  isUseCouponParentEvent: (_, getters) => {
    return getters.parentEventDetail?.shopMenus?.coupon;
  },
  canUsePointFlag: (_, getters) => {
    return getters.parentEventDetail?.canUsePointFlag;
  },
  isPublicPointFlag: (_, getters) => {
    return getters.parentEventDetail?.publicPointFlag;
  },
  hasChargeManual: (_, getters) => {
    return getters.childEventDetail?.hasChargeManual;
  },
  hasPaymentCancelCurrency: (_, getters) => {
    return getters.childEventDetail?.hasPaymentCancelCurrency;
  },
  hasSmsParentEvent: (_, getters) => {
    return getters.parentEventDetail?.useSms && getters.parentEventDetail?.useLoginSms;
  }
};
