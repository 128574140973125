import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialCoupon = () => ({
  couponList: [],
  couponCount: 0,
  allInvalid: false,
  couponUseList: [],
  couponUseCount: 0,
  couponDetail: {},
  couponUseTotal: 0,
  couponIndividualList: [],
  couponIndividualCount: 0,
});

export default {
  state: initialCoupon(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
