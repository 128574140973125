import { CommonConstants } from '@/constants/common';
import permission from '@/helpers/permission';
import { RoleConstants } from '@/constants/role';

export default {
  token: (state) => state.token,
  passwordExpiresAt: (state) => state.passwordExpiresAt,
  authEmailFlag: (state) => state.authEmailFlag,
  isBlockAuthen: (state) => state.isBlockAuthen,
  infor: (state) => state.infor,
  isUseOmise: (state) => !!state.infor.setUseOmise,
  isUseSms: (state) => !!state.infor.setUseSms,
  isUseVerificationIdentity: (state) => !!state.infor.setUseVerificationIdentity,
  envPaymentPasscode: (state) => state.infor?.envPaymentPasscode,
  userRole: (state) => {
    if (state.infor) {
      return state.infor.kind;
    }
    return null;
  },
  commonSubdomain: (_, getters, rootState, rootGetters) => {
    return rootGetters['common/isGmoOrOfficeLoginStore'] ? rootGetters['common/subdomain'] : getters.infor?.event?.subdomain;
  },
  commonShopId: (_, getters, rootState, rootGetters) => {
    const shopId = rootGetters['shop/newShopId'] || Number(localStorage.getItem('shopId')) || null;
    return permission.isGmoOrOffice() ? shopId : getters.infor?.shop?.id;
  },
  userChildRoles: (state) => state.infor.role,
  expiredTime: (state) => {
    const expirationDate = new Date(state.passwordExpiresAt);
    const now = Date.now();
    return expirationDate.getTime() - now;
  },
  isPasswordExpired: (state, getters) => {
    if (!state.passwordExpiresAt) {
      return false;
    }
    return getters.expiredTime < 0;
  },
  isNearExpiredPassword: (state, getters) => {
    if (!state.passwordExpiresAt) {
      return false;
    }
    const remainingTime = CommonConstants.MILLISECONDS_IN_DAY * CommonConstants.TIME_SHOW_NOTIFACTION_PASSWORD;
    return getters.expiredTime < remainingTime;
  },
  isOfficeHadEvent: (state) => {
    if (permission.isOffice()) {
      return state.infor?.eventCount > 0
    }
    return true;
  },
  isClientChild: (state) => {
    if (permission.isOffice()) {
      return !!state.infor?.company?.parent;
    }
    return false;
  },
  isClientParent: (_, getters) => {
    return permission.isOffice() && !getters.isClientChild;
  },
  hasShopRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE) || permission.isGmo() || permission.isStoreOrGroup();
  },
  hasTransactionRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_TRANSACTIONS_OFFICE) || permission.isGmo() || permission.isStoreOrGroup();
  },
  hasCustomersRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_CUSTOMERS_OFFICE) || permission.isGmo();
  },
  isUserableCPM: (state) => state.infor?.userableCPM,
  cpmFlag: (state) => state.infor?.shop?.cpmFlag,
  haveCpmUser: (state) => state.infor?.haveCpmUser,
  hasAggregateRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_AGGREGATES_OFFICE) || permission.isGmo();
  },
  hasUsersRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_USERS_OFFICE) || permission.isGmo();
  },
  hasCouponsRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_COUPONS_OFFICE) || permission.isGmo();
  },
  hasEventsRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_EVENTS_OFFICE) || permission.isGmo();
  },
  hasManageShopsRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_MANAGE_SHOPS_OFFICE) || permission.isGmo();
  },
  isClientChildOrStoreGroup: (_, getters) => {
    return getters.isClientChild || permission.isStoreOrGroup();
  },
  hasManageCurrencyRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_MANAGE_CURRENCY_OFFICE) || permission.isGmo();
  },
  hasManageQrRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_MANAGE_QR_OFFICE) || permission.isGmo();
  },
  hasPointsRole: (state) => {
    return state.infor?.role?.includes(RoleConstants.ROLE_POINTS_OFFICE) || permission.isGmo();
  },
};
