export default {
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_BUTTON_LOADING(state, isButtonLoading) {
    state.isButtonLoading = isButtonLoading;
  },
  OPEN_ERROR_MODAL(state, isErrorModalOpened) {
    state.isErrorModalOpened = isErrorModalOpened;
  },
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },
  SET_ERROR_FIELDS(state, errorFields) {
    state.errorFields = errorFields;
  },
  RESET_ERROR_FIELDS(state) {
    state.errorFields = {};
  },
  SET_UPLOADED_IMAGE(state, image) {
    state.uploadedImage = image;
  },
  SET_CARD_TEMPLATE_LIST(state, list) {
    state.cardTemplateList = list;
  },
  SET_CHARGE_AMOUNT_CHOICES(state, list) {
    state.chargeAmountChoices = list;
  },
  SET_GENRES(state, genres) {
    state.genres = genres;
  },
  SET_PAYMENT_CYCLES(state, paymentCycles) {
    state.paymentCycles = paymentCycles;
  },
  SET_PREFECTURES(state, prefectures) {
    state.prefectures = prefectures;
  },
  SET_MUNICIPALITIES(state, municipalities) {
    state.municipalities = municipalities;
  },
  SET_TOPIC_TEMPLATE_LIST(state, topicTemplates) {
    state.topicTemplates = topicTemplates;
  },
  SET_BACKGROUND_TEMPLATE_LIST(state, backgroundTemplates) {
    state.backgroundTemplates = backgroundTemplates;
  },
  SET_USER_PRIVILEGE_LIST(state, userPrivileges) {
    state.userPrivileges = userPrivileges;
  },
  SET_UPLOAD_ERRORS(state, payload) {
    state.uploadErrors = [
      ...state.uploadErrors,
      payload
    ];
  },
  REMOVE_FROM_UPLOAD_ERRORS(state, index) {
    state.uploadErrors.splice(index, 1);
  },
  SET_CONVENIENCE_STORES(state, convenienceStores) {
    state.convenienceStores = convenienceStores;
  },
  SET_IS_OLD_EVENT(state, isOldEvent) {
    if (isOldEvent) {
      localStorage.setItem('isOldEvent', isOldEvent);
    } else {
      localStorage.removeItem('isOldEvent');
    }
    state.isOldEvent = isOldEvent;
  }
};
