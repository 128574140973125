import http from '@/config/api';
import { getFirstDateThisMonth, getLastDateThisMonth } from '@/filters/date';
import router from '@/router';

export default {
  getShopCoupons({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shop/${payload.shopId}/coupons`, {
        params: payload,
      })
      .then((response) => {
        if (response) {
          commit('SET_COUPON_LIST', response.data.list);
          commit('SET_COUPON_COUNT', response.data.count);
        }
      });
  },
  getEventCoupons({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shops/coupons`, { params: payload })
      .then((response) => {
        if (response) {
          commit('SET_COUPON_LIST', response.data.list);
          commit('SET_COUPON_COUNT', response.data.count);
          commit('SET_ALL_EXPIRED', response.data.allInvalid);
        }
      });
  },
  createCoupon(_, payload) {
    return http.post(
      `event/${payload.subdomain}/shop/${payload.shopId}/coupon`,
      payload.data
    );
  },
  getCouponDetail({ commit }, payload) {
    return http
      .get(
        `event/${payload.subdomain}/shop/${payload.shopId}/coupon/${payload.id}`
      )
      .then((response) => {
        if (response) commit('SET_COUPON_DETAIL', response.data);
      });
  },
  updateCoupon(_, payload) {
    return http.post(
      `event/${payload.subdomain}/shop/${payload.shopId}/coupon/${payload.id}`,
      payload.data
    );
  },
  deleteShopCoupon(_, payload) {
    return http.delete(
      `event/${payload.subdomain}/shop/${payload.shopId}/coupons`,
      {
        data: payload.data,
      }
    );
  },
  deleteEventCoupon(_, payload) {
    return http.delete(`event/${payload.subdomain}/shops/coupons`, {
      data: payload.data,
    });
  },
  updateCouponStatus(_, payload) {
    return http.post(
      `event/${payload.subdomain}/shop/${payload.shopId}/coupon/${payload.id}/status`,
      payload.data
    );
  },
  getShopUseCoupons({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shop/${payload.shopId}/couponResults`, {
        params: payload,
      })
      .then((response) => {
        if (response) {
          commit('SET_COUPON_USE_LIST', response.data.list);
          commit('SET_COUPON_USE_COUNT', response.data.count);
        }
      });
  },
  getEventUseCoupons({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shops/couponResults`, {
        params: payload,
      })
      .then((response) => {
        if (response) {
          commit('SET_COUPON_USE_LIST', response.data.list);
          commit('SET_COUPON_USE_COUNT', response.data.count);
        }
      });
  },
  getShopCouponUseTotal({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shop/${payload.shopId}/couponResults`, {
        params: {
          startDate: getFirstDateThisMonth(),
          endDate: getLastDateThisMonth(),
          limit: 1,
        },
      })
      .then((response) => {
        if (response) commit('SET_COUPON_USE_TOTAL', response.data.count);
      });
  },
  getEventCouponUseTotal({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shops/couponResults`, {
        params: {
          startDate: getFirstDateThisMonth(),
          endDate: getLastDateThisMonth(),
          limit: 1,
        },
      })
      .then((response) => {
        if (response) commit('SET_COUPON_USE_TOTAL', response.data.count);
      });
  },
  async getShopCouponCSV(_, params) {
    return await http
      .post(`event/${params.subdomain}/shop/${params.shopId}/couponResults/export`, params)
      .then((response) => response?.data);
  },
  async getEventCouponCSV(_, params) {
    return await http
      .post(`event/${params.subdomain}/shops/couponResults/export`, params)
      .then((response) => response?.data);
  },
  getIndividualCoupons({ commit }, payload) {
    const shopId = payload?.shopId || router.currentRoute.params.shopId;
    const id = payload?.id || router.currentRoute.params.id;
    return http
      .get(`event/${payload.subdomain}/shop/${shopId}/coupon/${id}/individual`, {
        params: payload,
      })
      .then((response) => {
        if (response) {
          commit('SET_COUPON_INVIDUAL_LIST', response.data.list);
          commit('SET_COUPON_INVIDUAL_COUNT', response.data.count);
        }
      });
  },
  importCouponCsv(_, form) {
    const subdomain = form.subdomain || router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/shop/coupon/import/individual`, form, { isMultipartContent: true });
  },
  createDraftCustomers(_, params) {
    return http
      .post(`event/${params.subdomain}/shop/coupon/draft`, params)
      .then((response) => response?.data);
  },
  updateDraftCustomers(_, params) {
    return http
      .post(`event/${params.subdomain}/shop/coupon/draft/${params.draftId}`, params)
      .then((response) => response?.data);
  },
  deleteDraftCustomers(_, params) {
    return http.delete(`event/${params.subdomain}/shop/coupon/draft/${params.draftId}`);
  },
  distributeCoupons(_, params) {
    return http
      .post(`event/${params.subdomain}/shop/coupon/${params.draftId}/distribution`)
      .then((response) => response?.data);
  },
  checkUseCouponManual(_, params) {
    return http
      .get(`event/${params.subdomain}/checkUseCouponManual`)
      .then((response) => response?.data?.result);
  },
};
