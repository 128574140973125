export default {
  SET_CATEGORIES_LIST(state, list) {
    state.categoriesList.list = list;
  },
  SET_CATEGORY_COUNT(state, count) {
    state.categoriesList.count = count;
  },
  SET_QR_TICKET_LIST(state, list) {
    state.qrTicketList.list = list;
  },
  SET_QR_TICKET_COUNT(state, count) {
    state.qrTicketList.count = count;
  },
  SET_TICKET_QR_DETAIL(state, ticketQrDetail) {
    state.ticketQrDetail = ticketQrDetail;
  },
  SET_QR_CODE_TICKETS(state, list) {
    state.qrCodeTickets.list = list;
  },
  SET_ISSUER_QR_CODE_LIST(state, list) {
    state.issuerQrCodes.list = list;
  },
  RESET_ISSUER_QR_CODE_LIST(state) {
    state.issuerQrCodes.list = [];
  },
  SET_CHARGE_TICKETS(state, list) {
    state.chargeTickets.list = list;
  },
  SET_PAYMENT_TICKETS(state, list) {
    state.paymentTickets.list = list;
  },
  SET_RELEASE_OTP_LIST(state, list) {
    state.releaseOtpList.list = list;
  },
  SET_RELEASE_OTP_COUNT(state, count) {
    state.releaseOtpList.count = count;
  },
  SET_OTP_LIST(state, list) {
    state.otpList.list = list;
  },
  SET_OTP_COUNT(state, count) {
    state.otpList.count = count;
  },
  SET_OTP_INACTIVE_COUNT(state, inactiveCount) {
    state.otpList.inactiveCount = inactiveCount;
  },
  RESET_OTP_LIST(state) {
    state.otpList.list = [];
    state.otpList.count = 0;
  },
  SET_ALL_QR_TICKET_LIST(state, list) {
    state.allQrTicketList.list = list;
  },
  SET_ALL_QR_TICKET_COUNT(state, count) {
    state.allQrTicketList.count = count;
  },
  RESET_ALL_QR_TICKET_LIST(state) {
    state.allQrTicketList.list = [];
    state.allQrTicketList.count = 0;
  },
  SET_AMOUNT_STORE_LIST(state, list) {
    state.amountStoreList = list;
  }
};
