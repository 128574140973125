import { CommonConstants } from '@/constants/common'

export default {
  bind(el, binding) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const inputEvent = isSafari ? 'keydown' : 'input';
    el.addEventListener(inputEvent, (e) => {
      let value;
      if (!['numberAndCharC'].includes(binding.value)) {
        value = e.target.value.replace(CommonConstants.NUMBER_VALIDATION_REGEX, '');
      }
      switch (binding.value) {
        case 'integer':
          value = value ? Number(value) : '';
          break;
        case 'numberAndCharC':
          if (!CommonConstants.NUMBER_AND_C_VALIDATION_REGEX.test(e.target.value)) {
            value = e.target.value.replace(e.target.value, '')
          } else {
            value = e.target.value;
          }
          break;
        case 'numberCharacterAndHyphen':
          if (!CommonConstants.NUMBER_CHARACTER_AND_HYPHEN_VALIDATION_REGEX.test(e.target.value)) {
            value = e.target.value.replace(e.target.value, e.target.value.slice(0, e.target.value.length - 1))
          } else {
            value = e.target.value;
          }
          break;
        case 'numberAndComma':
          if (!CommonConstants.NUMBER_HALFSIZE_AND_COMMA_REGEX.test(e.target.value)) {
            value = e.target.value.replace(e.target.value, e.target.value.slice(0, e.target.value.length - 1))
          } else {
            value = e.target.value;
          }
          break;
      }
      e.target.value = value;
      el.value = value;
    }, true);
  },
};
