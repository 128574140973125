import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialShop = () => ({
  shopList: {
    list: [],
    count: 0,
  },
  shopTotal: 0,
  shopDetail: {},
  approvedShopTotal: 0,
  requestedShopTotal: 0,
  sortText: '',
  sortType: '',
  config: {},
  newShopId: Number(localStorage.getItem('shopId')) || null,
});

export default {
  state: initialShop(),
  mutations,
  actions,
  getters,
  namespaced: true
};
