const convertFullwidthAlphabet = (fullWidthCharacter) => {
  const replacedFullsizeSpaceCharacter = fullWidthCharacter.replace(/[\u3000]/g, ' ');
  return replacedFullsizeSpaceCharacter.replace(/[\uff01-\uff5e]/g, function(m) {
    return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
  });
};


export default {
  bind(el, binding, vnode) {
    el.addEventListener(
      "blur",
      (e) => {
        let value = e.target.value;
        value = convertFullwidthAlphabet(value);
        e.target.value = value.toLowerCase();
        el.value = value.toLowerCase();

        let event;
        if (typeof Event === "function") {
          event = new Event("input", { bubbles: true });
        } else {
          event = document.createEvent("Event");
          event.initEvent("input", true, true);
        }
        vnode.elm.dispatchEvent(event);
      },
      true
    );
  },
};
