<template>
  <transition name="alert" appear>
    <div class="alert-content" :class="{ 'alert-success': this.type === 'success', 'alert-error': this.type === 'error' }">
      <div class="alert-message">
        <p class="alert-message-text" :class="{ 'font-alert': isCustomFont }">{{ this.message }}</p>
      </div>
      <button class="alert-close" type="button" v-on:click="closeAlert()"></button>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    message: String,
    type: String,
    id: String,
    autoclose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTimeout: -1,
    };
  },
  computed: {
    ...mapGetters({
      errorFields: 'common/errorFields'
    }),
    isCustomFont() {
      return this.message?.includes("¥");
    }
  },
  methods: {
    setTimeout() {
      window.clearTimeout(this.activeTimeout);
      if (this.type === 'success' && !this.autoclose) {
        this.activeTimeout = window.setTimeout(() => {
          this.closeAlert();
        }, 3000);
      }
    },
    closeAlert() {
      if (this.errorFields?.issueCoupon) window.location.reload();
      this.$emit('remove');
    },
  },
  mounted() {
    this.setTimeout();
  },
};
</script>

<style scoped>
  .font-alert {
    font-family: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Helvetica, Arial, sans-serif !important;
  }
</style>
