import http from '@/config/api';

export default {
  getMenus({ commit }, payload) {
    return http.get(`event/${payload.subdomain}/shop/${payload.shopId}/menus`, { params: payload }).then((response) => {
      if (response) commit('SET_MENU_LIST', response.data);
    });
  },
  getMenuDetail({ commit }, payload) {
    return http.get(`event/${payload.subdomain}/shop/${payload.shopId}/menu/${payload.id}`).then((response) => {
      if (response) commit('SET_MENU_DETAIL', response.data.list);
    });
  },
  createMenu(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/menu`, payload.data);
  },
  updateMenu(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/menu/${payload.id}`, payload.data);
  },
  deleteMenu(_, payload) {
    return http.delete(`event/${payload.subdomain}/shop/${payload.shopId}/menus`, {
      data: payload.data,
    });
  },
  sortMenus(_, payload) {
    return http.post(
      `event/${payload.subdomain}/shop/${payload.shopId}/menuCategory/${payload.categoryId}/sort `,
      payload.data
    );
  },
  getCategories({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shop/${payload.shopId}/menuCategories`, { params: { limit: 20 } })
      .then((response) => {
        if (response) commit('SET_CATEGORY_LIST', response.data);
      });
  },
  createCategory(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/menuCategory`, payload.data);
  },
  updateCategory(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/menuCategory/${payload.id}`, payload.data, {
      notLoading: true,
    });
  },
  deleteCategory(_, payload) {
    return http.delete(`event/${payload.subdomain}/shop/${payload.shopId}/menuCategories`, {
      data: payload.data,
    });
  },
  sortCategories(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/menuCategories/sort `, payload.data);
  },
};
