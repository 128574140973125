export default {
  SET_CUSTOMER_LIST(state, list) {
    state.customerList.list = list;
  },
  SET_COUNT_CUSTOMER_LIST(state, count) {
    state.customerList.count = count;
  },
  SET_CUSTOMER_TOTAL(state, number) {
    state.customerTotal = number;
  },
  SET_CUSTOMER_CPM_TOTAL(state, number) {
    state.customerCpmTotal = number;
  },
  SET_CUSTOMER_ACTIVITIES(state, list) {
    state.customerActivities = list;
  },
  SET_CUSTOMER_DETAIL(state, customerDetail) {
    state.customerDetail = customerDetail;
  },
  UPDATE_CUSTOMER_DETAIL(state, payload) {
    state.customerDetail = { ...state.customerDetail, ...payload };
  },
  SET_TOTAL_UNSUBSCRIBE(state, number) {
    state.totalUnsubscribe = number;
  },
  SET_HAS_CUSTOMER_TOTAL(state, number) {
    state.hasCustomerTotal = number;
  },
  RESET_CUSTOMER_LIST(state) {
    state.customerList.list = [];
    state.customerList.count = 0;
  },
  SET_TEMPLATES_LIST(state, list) {
    state.templatesList.list = list;
  },
  SET_TEMPLATES_COUNT(state, count) {
    state.templatesList.count = count;
  },
  SET_TEMPLATE_DETAIL(state, templateDetail) {
    state.templateDetail = templateDetail;
  },
  SET_NEWS_LIST(state, list) {
    state.newsList.list = list;
  },
  SET_NEWS_COUNT(state, count) {
    state.newsList.count = count;
  },
  SET_NEWS_DETAIL(state, newsDetail) {
    state.newsDetail = newsDetail;
  },
  SET_CHILD_LIST(state, list) {
    state.childList.list = list;
  },
  SET_EXIST_OMISE_NUMBER(state, payload) {
    state.existsOmiseNumber = payload;
  },
};
