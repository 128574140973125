import router from '@/router';
import message from '@/helpers/message';

const updateNestedObjectFromPath = (object, newValue, path) => {
  var stack = path.split('.');
  while (stack.length > 1) {
    object = object[stack.shift()];
  }
  object[stack.shift()] = newValue;
};

const generateRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

const disabledBtnBackBrowser = () => {
  history.pushState(history.state, '', location.href);
  window.onpopstate = function () {
    history.pushState(history.state, '', location.href);
  };
}

const getSubdomainAndDirectory = (params) => {
  const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
  const directory = params?.directory || router.currentRoute.params.directory;
  return { subdomain, directory };
}

const copyToClipboard = (text, data) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  if (data) message.showSuccess(data);
}

export { updateNestedObjectFromPath, generateRandomString, disabledBtnBackBrowser, getSubdomainAndDirectory, copyToClipboard };
