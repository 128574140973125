import store  from '@/store';
import { RouterConstants } from '@/constants/router';

const redirectAdminClientOrStoreRouter = (to) =>  {
  if (to.query?.callback) {
    const refreshToken = atob(to.query?.callback);
    localStorage.setItem('refreshToken', refreshToken);
    if (to.name === RouterConstants.DEFAULT_ROUTER_OF_SHOP_OR_GROUP && to.query?.shopId) {
      localStorage.setItem('shopId', to.query?.shopId);
      if (to.query?.isOldEvent) {
        store.commit('common/SET_IS_OLD_EVENT', Boolean(to.query?.isOldEvent));
      }
      localStorage.setItem('SUBDOMAIN', to.query?.subdomain || store.getters['common/subdomain']);
      store.dispatch('shop/setNewShopId', to.query?.shopId);
    } else if (to.name === 'EventShop') {
      localStorage.setItem('SUBDOMAIN', 'management');
      store.commit('common/SET_IS_OLD_EVENT', null);
      localStorage.removeItem('shopId');
    }
  }
}

export default redirectAdminClientOrStoreRouter;
