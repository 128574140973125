import http from '@/config/api';
import router from '@/router';
import { getSubdomainAndDirectory } from '@/helpers/common';

export default {
  getHistoryCharges({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`/event/${subdomain}/child/${directory}/charges`, { params }).then((response) => {
        if (response) {
          commit('SET_HISTORY_CHARGES', response.data.list);
          commit('SET_HISTORY_CHARGES_COUNT', response.data.count);
          commit('SET_HISTORY_TOTAL_ADDED', response.data.totalAdded);
        }
      });
  },
  getHistoryChargesTicket({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`/event/${subdomain}/child/${directory}/charges/ticket`, { params }).then((response) => {
        if (response) {
          commit('SET_HISTORY_CHARGES', response.data.list);
          commit('SET_HISTORY_CHARGES_COUNT', response.data.count);
          commit('SET_HISTORY_TOTAL_ADDED', response.data.totalAdded);
        }
      });
  },
  getHistoryChargeDetail({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`event/${subdomain}/child/${directory}/charge/${params.id}`)
      .then((response) => {
        if (response) commit('SET_HISTORY_CHARGE_DETAIL', response.data);
      });
  },
  getHistoryPayments({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`/event/${subdomain}/child/${directory}/payments`, {
        params,
        ...params.config,
       }).then((response) => {
        if (response) {
          commit('SET_HISTORY_PAYMENTS', response.data.list);
          commit('SET_HISTORY_PAYMENTS_COUNT', response.data.count);
          commit('SET_HISTORY_PAYMENTS_TOTAL', response.data.aggregate.totalAmount);
          commit('SET_HISTORY_PAYMENTS_BALANCE', response.data.aggregate.balance);
        }
      });
  },
  getHistoryPaymentsTicket({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`event/${subdomain}/child/${directory}/payments/ticket/index`, {
        params,
        ...params.config,
       }).then((response) => {
        if (response) {
          commit('SET_HISTORY_PAYMENTS', response.data.list);
          commit('SET_HISTORY_PAYMENTS_COUNT', response.data.count);
          commit('SET_HISTORY_PAYMENTS_TOTAL', response.data.aggregate.totalAmount);
          commit('SET_HISTORY_PAYMENTS_BALANCE', response.data.aggregate.balance);
        }
      });
  },
  getHistoryPaymentDetail({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`event/${subdomain}/child/${directory}/payment/${params.id}`)
      .then((response) => {
        if (response) commit('SET_HISTORY_PAYMENT_DETAIL', response.data);
      });
  },
  async getHistoryChargeCSV({ state }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http
      .post(`event/${subdomain}/child/${directory}/charges/export`, {
        ...params,
        orderBy: state.sortText,
        sortOrder: state.sortType,
      })
      .then((response) => response?.data);
  },
  async getHistoryChargeTicketCSV({ state }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http
      .post(`event/${subdomain}/child/${directory}/charges/ticket/export`, {
        ...params,
        orderBy: state.sortText,
        sortOrder: state.sortType,
      })
      .then((response) => response?.data);
  },
  getHistoryPaymentCSV({ state }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http
      .get(`event/${subdomain}/child/${directory}/payments?output=csv`, {
        params: {
          ...params,
          orderBy: state.sortText,
          sortOrder: state.sortType,
        },
      })
      .then((response) => response?.data);
  },
  setIsHistoryPaymentsNotSeen({ commit }, payload) {
    commit('SET_IS_HISTORY_PAYMENTS_NOT_SEEN', payload);
  },
  resetHistoryPayments({ commit }) {
    commit('RESET_HISTORY_PAYMENTS');
  },
  resetHistoryPaymentDetail({ commit }) {
    commit('RESET_HISTORY_PAYMENT_DETAIL');
  },
  async cancelHistoryPayment(_, payload) {
    const directory = payload?.directory || router.currentRoute.params.directory;
    return await http.delete(`event/${payload.subdomain}/child/${directory}/payment/${payload.id}`);
  },
  async exportCSVHistoryPayment(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return await http
      .post(`event/${subdomain}/child/${directory}/payments/export`, payload.data)
      .then((response) => response?.data);
  },
  async exportCSVHistoryPaymentTicket(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return await http
      .post(`event/${subdomain}/child/${directory}/payments/ticket/export`, payload.data)
      .then((response) => response?.data);
  },
  async getStatusExportCSV(_, id) {
    return await http.post(`export/status/${id}`,_, {
      notLoading: true,
    }).then((response) => response?.data);
  },
  getTicketList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.get(`/event/${subdomain}/child/${directory}/history/ticket`)
      .then((response) => {
        if (response) commit('SET_TICKET_LIST', response.data?.list);
      });
  },
  getMethodList({ commit }, params) {
    const subdomain = router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return http
      .get(`/event/${subdomain}/child/${directory}/charge/methods`, { params }).then((response) => {
        if (response) {
          commit('SET_METHOD_LIST', response.data);
        }
      });
  },
  async cancelHistoryCharge(_, payload) {
    const directory = payload?.directory || router.currentRoute.params.directory;
    return await http.post(`event/${payload.subdomain}/child/${directory}/charge/${payload.id}/cancel`);
  },
};
