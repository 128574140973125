import store from '@/store';
import { RoleConstants } from '../constants/role';

const checkPermission = (roles) => {
  const userRole = store.getters['auth/userRole'];
  return roles.includes(userRole);
}

const permission = {
  isStore: () => checkPermission([RoleConstants.ROLE_STORE]),
  isGmo: () => checkPermission([RoleConstants.ROLE_GMO]),
  isOffice: () => checkPermission([RoleConstants.ROLE_OFFICE]),
  isGroup: () => checkPermission([RoleConstants.ROLE_GROUP]),
  isGmoOrOffice: () => checkPermission(RoleConstants.ROLE_GMO_OR_OFFICE),
  isStoreOrOffice: () => checkPermission(RoleConstants.ROLE_STORE_OR_OFFICE),
  isStoreOrGroup: () => checkPermission(RoleConstants.ROLE_STORE_OR_GROUP)
}

export default permission;
