import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialAggregate = () => ({
  payment: {
    amount: {
      list: [],
      total: 0,
    },
    count: {
      list: [],
      total: 0,
    },
  },
  paymentTotal: {},
  previousPaymentAmount: null,
  previousPaymentCount: null,
  shop: {
    list: [],
    count: 0,
  },
  parentEventAggregate: {
    active: 0,
    deleted: 0,
    gender: {},
    age: {},
    shops: {},
  },
  childEventAggregate: {
    charges: {},
    payments: {},
  },
  companyChildEventAggregate: {},
  sortText: '',
  sortType: '',
  companyChildEventAggregateTotal: 0
});

export default {
  state: initialAggregate(),
  mutations,
  actions,
  getters,
  namespaced: true
};
