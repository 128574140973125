export default {
  couponList: (state) => state.couponList,
  couponCount: (state) => state.couponCount,
  allInvalid: (state) => state.allInvalid,
  couponDetail: (state) => state.couponDetail,
  couponUseList: (state) => state.couponUseList,
  couponUseCount: (state) => state.couponUseCount,
  couponUseTotal: (state) => state.couponUseTotal,
  couponIndividualList: (state) => state.couponIndividualList,
  couponIndividualCount: (state) => state.couponIndividualCount,
};
