import http from '@/config/api';

export default {
  getQrCodeList({ commit }, payload) {
    return http.get(`event/${payload.subdomain}/shops/qrcodes`, { params : payload }).then((response) => {
      if (response) {
        commit('SET_QR_CODE_LIST', response.data.list.filter((el) => el.value));
        commit('SET_QR_CODE_COUNT', response.data.count);
      }
    })
  }
}