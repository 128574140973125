export default {
  paymentAmountList: (state) => state.payment.amount.list,
  paymentCountList: (state) => state.payment.count.list,
  paymentAmountTotal: (state) => state.payment.amount.total,
  paymentCountTotal: (state) => state.payment.count.total,
  paymentTotal: (state) => state.paymentTotal,
  previousPaymentAmount: (state) => state.previousPaymentAmount,
  previousPaymentCount: (state) => state.previousPaymentCount,
  shopAggregateList: (state) => state.shop.list,
  shopAggregateCount: (state) => state.shop.count,
  parentEventAggregate: (state) => state.parentEventAggregate,
  childEventAggregate: (state) => state.childEventAggregate,
  companyChildEventAggregate: (state) => state.companyChildEventAggregate,
  companyChildEventAggregateTotal: (state) => state.companyChildEventAggregateTotal,
  sortText: (state) => state.sortText,
  sortType: (state) => state.sortType,
};
