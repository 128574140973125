import http from '@/config/api';
import router from '@/router';
import { StatusConstants } from '@/constants/status';

export default {
  async getShopList({ commit }, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    await http.get(`event/${subdomain}/shops`, { params }).then(response => {
      if (response) {
        commit('SET_SHOP_LIST', response.data.list);
        commit('SET_SHOP_LIST_COUNT', response.data.count);
      }
    });
  },
  async getShopTotal({ commit }, params) {
    await http.get(`/event/${params.subdomain}/shops`, { params }).then(response => {
        if (response)
          commit('SET_SHOP_TOTAL', response.data.count);
      })
  },
  async getShopDetail({ commit }, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    await http.get(`event/${subdomain}/shop/${params.id}`).then((response) => {
      if (response) {
        const data = response.data;
        data.genre = data.genre?.id,
        data.eventGroupId = data.group?.id,
        commit('SET_SHOP_DETAIL', data);
      }
    });
  },
  registerShop(_, form) {
    form = {
      ...form,
      companyName: form.company,
      categories: form.categories.map((item) => item.id),
    };
    const subdomain = router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/shop`, form);
  },
  async getShopTotalByStatus({ commit }, status) {
    const subdomain = router.currentRoute.params.subdomain;
    await http.get(`event/${subdomain}/shops`, { params: { status } }).then((response) => {
      if (response) {
        const { count } = response.data;
        if (status === StatusConstants.shop.approved.value) {
          commit('SET_APPROVED_SHOP_TOTAL', count);
        }
        if (status === StatusConstants.shop.waiting.value) {
          commit('SET_REQUESTED_SHOP_TOTAL', count);
        }
      }
    });
  },
  updateShop(_, form) {
    form = {
      ...form,
      categories: form.categories.map((item) => item.id),
    };
    const subdomain = form.subdomain || router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/shop/${form.id}`, form);
  },
  updateShopExamination(_, form) {
    const subdomain = router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/shops/status`, form).then(response => response?.data);
  },
  updateShopStatus(_, form) {
    const subdomain = router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/shop/${form.shopId}/status`, {
      status: form.status
    });
  },
  deleteShop(_, shopId) {
    const subdomain = router.currentRoute.params.subdomain;
    return http.delete(`event/${subdomain}/shop/${shopId}`);
  },
  async getShopConfig({ commit }, { subdomain, shopId }) {
    await http.get(`event/${subdomain}/shop/${shopId}/config`).then((response) => {
      if (response) {
        const data = response.data;
        data.images = data.images || [];
        data.receptions = data.receptions || [];
        commit('SET_SHOP_CONFIG', data);
      }
    });
  },
  updateDefaultConfig(_, { form, subdomain, shopId }) {
    return http.post(`event/${subdomain}/shop/${shopId}/config/first`, form);
  },
  updateBaseConfig(_, { form, subdomain, shopId }) {
    return http.post(`event/${subdomain}/shop/${shopId}/config/basic`, form);
  },
  updateDetailConfig(_, { form, subdomain, shopId }) {
    return http.post(`event/${subdomain}/shop/${shopId}/config/detail`, form);
  },
  async downloadShopList({ state }, params) {
    const subdomain = router.currentRoute.params.subdomain;
    return await http.post(`/event/${subdomain}/shops/export`, {
      ...params,
      orderBy: state.sortText,
      sortOrder: state.sortType,
    }).then((response) => response?.data);
  },
  importShopCsv(_, form) {
    const subdomain = form.subdomain || router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/shops/import`, form, { isMultipartContent: true });
  },
  getImportStatus(_, id) {
    return http.get(`import/status/${id}`).then((response) => {
      return response?.data;
    });
  },
  setNewShopId({ commit }, newShopId) {
    return commit('SET_NEW_SHOP_ID', newShopId);
  },
};
