export default {
  SET_PARENT_EVENT_LIST(state, list) {
    state.parentEventList.list = list;
  },
  SET_COUNT_PARENT_EVENT_LIST(state, count) {
    state.parentEventList.count = count;
  },
  SET_EXIST_DATA_EVENT_LIST(state, data) {
    state.parentEventList.existData = data;
  },
  SET_CHILD_EVENT_LIST(state, list) {
    state.childEventList.list = list;
  },
  SET_CHILD_EVENT_TYPE(state, payload) {
    state.childEventType = payload
  },
  SET_COUNT_CHILD_EVENT_LIST(state, count) {
    state.childEventList.count = count;
  },
  RESET_CHILD_EVENT_LIST(state) {
    state.childEventList = {
      list: [],
      count: 0,
    };
  },
  SET_NEW_CHILD_EVENT(state, payload) {
    state.newEvent = { ...state.newEvent, ...payload };
  },
  RESET_NEW_CHILD_EVENT(state) {
    state.newEvent = {};
  },
  SET_CHILD_EVENT_DETAIL(state, payload) {
    state.childEventDetail = { ...state.childEventDetail, ...payload };
  },
  SET_EVENT_TYPE(state, payload) {
    state.eventType = payload;
  },
  RESET_CHILD_EVENT_DETAIL(state) {
    state.childEventDetail = {};
  },
  SET_PARENT_EVENT_DETAIL(state, payload) {
    state.parentEventDetail = { ...state.parentEventDetail, ...payload };
  },
  RESET_PARENT_EVENT_DETAIL(state) {
    state.parentEventDetail = {};
  },
  SET_ERROR_FIELDS(state, payload) {
    state.errorFields = payload;
  },
  RESET_ERROR_FIELDS(state) {
    state.errorFields = {};
  },
  SET_EVENT_GROUP_LIST(state, payload) {
    state.eventGroupList.list = payload;
  },
  SET_BANK_LIST(state, payload) {
    state.bankList.list = payload;
  },
  SET_COUNT_BANK(state, payload) {
    state.bankList.count = payload;
  }
};
