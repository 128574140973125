import { formatDataList } from '@/helpers/chartDate';

export default {
  SET_PARENT_EVENT_AGGREGATE(state, payload) {
    state.parentEventAggregate = { ...state.parentEventAggregate, ...payload };
  },
  SET_CHILD_EVENT_AGGREGATE(state, payload) {
    state.childEventAggregate = { ...state.childEventAggregate, ...payload };
  },
  SET_COMPANY_CHILD_EVENT_AGGREGATE(state, companyChildEventAggregate) {
    state.companyChildEventAggregate = companyChildEventAggregate;
  },
  SET_COMPANY_CHILD_EVENT_AGGREGATE_TOTAL(state, companyChildEventAggregateTotal) {
    state.companyChildEventAggregateTotal = companyChildEventAggregateTotal;
  },
  SET_SHOP_AGGREGATE(state, payload) {
    state.shop = {
      list: payload.list,
      count: payload.count,
    };
  },
  SET_PAYMENT_TOTAL(state, total) {
    state.paymentTotal = total;
  },
  SET_PAYMENT_AMOUNT(state, payload) {
    state.payment.amount = {
      list: formatDataList(payload.interval, payload.list, payload.startInterval).map(item => item?.amount || 0),
      total: payload.total?.amount,
    }
  },
  SET_PAYMENT_COUNT(state, payload) {
    state.payment.count = {
      list: formatDataList(payload.interval, payload.list, payload.startInterval).map(item => item?.count || 0),
      total: payload.total?.count,
    }
  },
  SET_PREVIOUS_PAYMENT_AMOUNT(state, payload) {
    state.previousPaymentAmount = payload;
  },
  SET_PREVIOUS_PAYMENT_COUNT(state, payload) {
    state.previousPaymentCount = payload;
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
};
