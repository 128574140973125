import { startOfWeek, addWeeks } from 'date-fns';
import { StatusConstants } from '@/constants/status';
import { formatUploadDate } from '@/helpers/formatData';

const now = new Date();
const thisMonth = now.getMonth() + 1;
const thisYear = now.getFullYear();

const getLastDateInMonth = () => {
  return new Date(thisYear, thisMonth, 0).getDate();
};

const getDatesInMonthList = (startInterval = 0) => {
  let lastDay;
  let dates = [];
  if (startInterval === 0) {
    lastDay = getLastDateInMonth();
  } else {
    lastDay = new Date(thisYear, thisMonth + startInterval, 0).getDate();
  }
  for (let i = 0; i < lastDay; i++) {
    let day = new Date(thisYear, now.getMonth() + startInterval, 1 + i);
    dates.push(day);
  }
  return dates;
};

const getMonthsInYearList = () => {
  let months = [];
  for (let i = 0; i < 12; i++) {
    let month = 1 + i;
    months.push(month);
  }
  return months;
};

const getDatesInWeekList = (startInterval = 0) => {
  const firstDateInWeek = addWeeks(startOfWeek(now), startInterval);
  let dates = [];
  for (let i = 0; i < 7; i++) {
    let day = new Date(thisYear, firstDateInWeek.getMonth(), firstDateInWeek.getDate() + i);
    dates.push(day);
  }
  return dates;
};

const formatDataList = (interval, data, startInterval) => {
  if (!interval || !data) {
    return [];
  }
  let dateList = [];
  switch(interval) {
    case StatusConstants.interval.weekly.value:
      dateList = getDatesInWeekList(startInterval);
      break;
    case StatusConstants.interval.monthly.value:
      dateList = getDatesInMonthList(startInterval);
      break;
    case StatusConstants.interval.yearly.value:
      dateList = getMonthsInYearList();
      break;
  }
  return dateList.map(time => {
    return data.find(item => {
      if (interval === StatusConstants.interval.yearly.value) {
        return time === Number.parseInt(item.startDate.split('-')[1]);
      }
      return formatUploadDate(time) === item.startDate
    });
  });
};

const getYearMonthDate = () => {
  return new Date().toISOString().split('T')[0];
};

export { getDatesInMonthList, getMonthsInYearList, getDatesInWeekList, formatDataList, getYearMonthDate };
