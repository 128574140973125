export default {
  categoriesList: (state) => state.categoriesList.list,
  categoryCount: (state) => state.categoriesList.count,
  qrTicketList: (state) => state.qrTicketList.list,
  qrTicketCount: (state) => state.qrTicketList.count,
  ticketQrDetail: (state) => state.ticketQrDetail,
  qrCodeTickets: (state) => state.qrCodeTickets.list,
  issuerQrCodes: (state) => state.issuerQrCodes.list,
  chargeTickets: (state) => state.chargeTickets.list,
  paymentTickets: (state) => state.paymentTickets.list,
  releaseOtpList: (state) => state.releaseOtpList.list,
  releaseOtpCount: (state) => state.releaseOtpList.count,
  otpList: (state) => state.otpList.list,
  otpCount: (state) => state.otpList.count,
  otpInactiveCount: (state) => state.otpList.inactiveCount,
  allQrTicketList: (state) => state.allQrTicketList.list,
  allQrTicketCount: (state) => state.allQrTicketList.count,
  amountStoreList: (state) => state.amountStoreList,
};
