import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialSettingUserCpm = () => ({
  cpmUserList: [],
  cpmUserCount: 0,
  cpmLoginLogList: [],
  cpmLoginLogCount: 0,
  cpmUserDetail: {}
});

export default {
  state: initialSettingUserCpm(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
