import http from '@/config/api';

export default {
  getGalleries({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shop/${payload.shopId}/galleries`, {
        params: { category: payload.categoryId, limit: payload.limit, offset: payload.offset },
      })
      .then((response) => {
        if (response) commit('SET_GALLERY_LIST', response.data);
      });
  },
  getGalleryDetail({ commit }, payload) {
    return http.get(`event/${payload.subdomain}/shop/${payload.shopId}/gallery/${payload.id}`).then((response) => {
      if (response) commit('SET_GALLERY_DETAIL', response.data.list);
    });
  },
  createGallery(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/gallery`, payload.data);
  },
  updateGallery(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/gallery/${payload.id}`, payload.data);
  },
  deleteGallery(_, payload) {
    return http.delete(`event/${payload.subdomain}/shop/${payload.shopId}/gallery/${payload.id}`);
  },
  sortGalleries(_, payload) {
    return http.post(
      `event/${payload.subdomain}/shop/${payload.shopId}/galleryCategory/${payload.categoryId}/sort `,
      payload.data
    );
  },
  getCategories({ commit }, payload) {
    return http
      .get(`event/${payload.subdomain}/shop/${payload.shopId}/galleryCategories`, { params: { limit: 20 } })
      .then((response) => {
        if (response) commit('SET_CATEGORY_LIST', response.data);
      });
  },
  createCategory(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/galleryCategory`, payload.data);
  },
  updateCategory(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/galleryCategory/${payload.id}`, payload.data, {
      notLoading: true,
    });
  },
  deleteCategory(_, payload) {
    return http.delete(`event/${payload.subdomain}/shop/${payload.shopId}/galleryCategories`, {
      data: payload.data,
    });
  },
  sortCategories(_, payload) {
    return http.post(`event/${payload.subdomain}/shop/${payload.shopId}/galleryCategories/sort `, payload.data);
  },
};
