import http from '@/config/api';
import router from '@/router';

export default {
  async createSettingPoint(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return await http.post(`/event/${subdomain}/child/${directory}/point/setting`, payload).then(response => response?.data);
  },
  async updateSettingPoint(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return await http.post(`/event/${subdomain}/child/${directory}/point/setting/${payload.pointId}`, payload).then(response => response?.data);
  },
  async getPointDetail({ commit }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    await http.get(`/event/${subdomain}/child/${directory}/point/setting`).then(response => {
      if (response) {
        commit('SET_CHILD_POINT_DETAIL', response.data?.list);
      }
    });
  },
  async getCategoriesList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    await http.get(`/event/${subdomain}/child/${directory}/point/categories`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_CATEGORIES_LIST', list);
        commit('SET_CATEGORY_COUNT', count);
      }
    });
  },
  async getPointStoreList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    await http.get(`/event/${subdomain}/child/${directory}/shops/point_setting`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_POINT_STORE_LIST', list);
        commit('SET_POINT_STORE_COUNT', count);
      }
    });
  },
  async addPointStore(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/shops/point_setting`, payload).then(response => response?.data);
  },
  async createCategory(_, payload) {
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${payload.subdomain}/child/${directory}/point/category`, payload)
      .then((response) => response?.data);
  },
  async updateCategory(_, payload) {
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${payload.subdomain}/child/${directory}/point/category/${payload.categoryId}`, payload)
      .then((response) => response?.data);
  },
  async deleteCategory(_, payload) {
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.delete(`/event/${payload.subdomain}/child/${directory}/point/category/${payload.categoryId}`, payload)
      .then((response) => response?.data);
  },
  async getHistoryPointList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return await http.get(`/event/${subdomain}/child/${directory}/point/histories`, { params }).then(response => {
      if (response) {
        const { list, count, point, plannedPoint } = response.data;
        commit('SET_HISTORY_POINT_LIST', list);
        commit('SET_HISTORY_POINT_COUNT', count);
        commit('SET_HISTORY_POINT', point);
        commit('SET_HISTORY_PLANNED_POINT', plannedPoint);
        return response?.data;
      }
    });
  },
  async getHistoryPointCSV({ state }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return await http
      .post(`event/${subdomain}/child/${directory}/point/histories/export`, {
        ...params,
        orderBy: state.sortText,
        sortOrder: state.sortType,
      }).then((response) => response?.data);
  },
  async getAggregatePoint({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    await http.get(`/event/${subdomain}/child/${directory}/point/aggregate`, { params }).then(response => {
      if (response) {
        commit('SET_AGGREGRATE_POINT_DETAIL', response.data);
      }
    });
  },
  createPointQR(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/point/qrcode`, payload).then(response => response?.data?.id);
  },
  updatePointQR(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/point/qrcode/${payload.id}`, payload).then(response => response?.data?.id);
  },
  getPointQRDetail({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return http.get(`/event/${subdomain}/child/${directory}/point/qrcode/${params.id}`).then(response => {
      if (response) {
        commit('SET_CHILD_POINT_QR_DETAIL', response.data);
        return response.data;
      }
    });
  },
  getPointQRList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    const directory = params?.directory || router.currentRoute.params.directory;
    return http.get(`/event/${subdomain}/child/${directory}/point/qrcodes`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_POINT_QR_LIST', list);
        commit('SET_POINT_QR_COUNT', count);
        return response?.data;
      }
    });
  },
  removePointQRs(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.delete(`/event/${subdomain}/child/${directory}/point/qrcodes`, {
      data: {
        ...payload
      }
    });
  },
  updatePointQrStatus(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(
      `event/${subdomain}/child/${directory}/point/qrcode/${payload.id}/status`,
      payload.data
    );
  },
  createPaymentManual(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/point/manual/payments`, payload)
  },
  createChargeManual(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`/event/${subdomain}/child/${directory}/point/manual/charges`, payload)
  },
  importManualCsv(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.post(`event/${subdomain}/child/${directory}/point/importPointPrivate`, payload, { isMultipartContent: true });
  },
}
