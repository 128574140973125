import http from '@/config/api';

export default {
  async getClientList({ commit }, params) {
    await http.get('/user/companies', { params }).then(response => {
      if (response) commit('SET_CLIENT_LIST', response.data.list);
    })
  },
  updateClient(_, payload) {
    return http.post(`/user/company/${payload.id}`, payload);
  },
}
