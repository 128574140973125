export default {
  SET_GALLERY_LIST(state, payload) {
    return (state.galleryList = payload);
  },
  SET_CATEGORY_LIST(state, payload) {
    return (state.categoryList = payload);
  },
  SET_GALLERY_DETAIL(state, payload) {
    return (state.galleryDetail = payload);
  },
};
