export default {
  SET_SHOP_LIST(state, list) {
    state.shopList.list = list;
  },
  SET_SHOP_TOTAL(state, count) {
    state.shopTotal = count;
  },
  SET_SHOP_LIST_COUNT(state, count) {
    state.shopList.count = count;
  },
  SET_SHOP_DETAIL(state, shopDetail) {
    state.shopDetail = shopDetail;
  },
  SET_APPROVED_SHOP_TOTAL(state, approvedShopTotal) {
    state.approvedShopTotal = approvedShopTotal;
  },
  SET_REQUESTED_SHOP_TOTAL(state, requestedShopTotal) {
    state.requestedShopTotal = requestedShopTotal;
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
  SET_SHOP_CONFIG(state, config) {
    state.config = config;
  },
  SET_NEW_SHOP_ID(state, newShopId) {
    state.newShopId = newShopId;
  },
  RESET_SHOP_LIST(state) {
    state.shopList = {
      list: [],
      count: 0,
    };
  },
};
