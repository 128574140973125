export default {
  historyCharges: (state) => state.historyCharges,
  historyChargesCount: (state) => state.historyChargesCount,
  historyChargeDetail: (state) => state.historyChargeDetail,
  historyPayments: (state) => state.historyPayments,
  historyPaymentsCount: (state) => state.historyPaymentsCount,
  historyPaymentDetail: (state) => state.historyPaymentDetail,
  historyPaymentTotal: (state) => state.historyPaymentTotal,
  historyPaymentBalance: (state) => state.historyPaymentBalance,
  isHistoryPaymentNotSeen: (state) => state.isHistoryPaymentNotSeen,
  ticketList: (state) => state.ticketList,
  methodList: (state) => state.methodList,
  totalAdded: (state) => state.totalAdded,
};
