import http from '@/config/api';

export default {
  async getNotificationList({ commit }, params) {
    await http.get('/notifyList', { params }).then((response) => {
      if (response) commit('SET_NOTIFICATION_LIST', response.data);
    })
  },
  createNotification(_, payload) {
    return http.post('/notify', payload);
  },
  deleteNotificationList(_, payload) {
    return http.delete('/notifyList', {
      data: {
        allFlag: payload.allFlag,
        id: payload.ids,
      }
    });
  },
  async getUserNotificationList({ commit }, params) {
    await http.get('/notices', { params }).then((response) => {
      if (response) commit('SET_USER_NOTIFICATION_LIST', response.data);
    })
  },
  async markNotificationRead({ commit }, id) {
    await http.delete(`/notify/${id}`).then((response) => {
      if (response) commit('DELETE_USER_NOTIFICATION', id);
    })
  },
}