import { isNowBetweenTwoDates } from '@/helpers/validators';

export default {
  notificationList: (state) => state.notificationList.list,
  notificationCount: (state) => state.notificationList.count,
  userNotificationList: (state) => {
    return state.userNotificationList.list.filter(item => {
      return isNowBetweenTwoDates(item.publicDate?.startDate, item.publicDate?.endDate);
    }) 
  },
  userNotificationCount: (_, getters) => getters.userNotificationList.count,
};
