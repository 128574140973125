import { CommonConstants } from '@/constants/common'

export default {
  bind(el, binding) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const inputEvent = isSafari ? 'keydown' : 'input';
    el.addEventListener(inputEvent, (e) => {
      const maxCharacters = binding.value || 0;
      let value;
      let parts;
      let isLastDotCharacter;
      isLastDotCharacter = e.target.value.includes('.') && (e.target.value.lastIndexOf('.') !== e.target.value.indexOf('.'));
      value = e.target.value.replace(CommonConstants.DECIMAL_REGEX, '');
      if (e.target.value[0] == '.' || isLastDotCharacter) {
        value = e.target.value.slice(0, -1);
      }
      if (e.target.value.indexOf('e') !== -1) {
        value = e.target.value.replace('e', '');
      }
      if (maxCharacters) {
        parts = value.split('.');
        if (parts.length > 1) {
          const decimalPart = parts[1];
          if (decimalPart.length > maxCharacters) {
            value = parts[0] + '.' + decimalPart.slice(0, maxCharacters);
          }
        }
      }
      e.target.value = value;
      el.value = value;
    }, true);
  },
};
