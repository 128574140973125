import { RoleConstants } from '@/constants/role';

export default [
  {
    path: '/',
    name: 'Default',
    meta: { requiredAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'ログイン' },
    component: () => import('../views/Login.vue'),
  },
  //--PASSWORD
  {
    path: '/password/default',
    name: 'PasswordDefault',
    meta: { title: 'パスワード初期設定', requiredAuth: true },
    component: () => import('../views/Password/Default.vue'),
  },
  {
    path: '/password/reset',
    name: 'PasswordReset',
    meta: { title: 'パスワード再設定', requiredAuth: true },
    component: () => import('../views/Password/Reset.vue'),
  },
  {
    path: '/password/code',
    name: 'PasswordCode',
    meta: { title: '確認コード', requiredAuth: true },
    component: () => import('../views/Password/Code.vue'),
  },
  //--EVENT
  {
    path: '/event/list',
    name: 'EventList',
    meta: {
      title: 'イベント一覧',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Event/List.vue'),
  },
  {
    path: '/event/:subdomain/dashboard',
    name: 'EventDashboardParent',
    meta: {
      title: 'ダッシュボード',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Event/DashboardParent.vue'),
  },
  {
    path: '/event/:subdomain/:directory/dashboard',
    name: 'EventDashboardChild',
    meta: {
      title: 'ダッシュボード',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Event/DashboardChild.vue'),
  },
  //--EVENT
  //--SHOP
  {
    path: '/event/:subdomain/shop',
    name: 'EventShop',
    meta: {
      title: '店舗管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_SHOPS_OFFICE,
      notClientChild: true
    },
    component: () => import('../views/Event/Shop/Account/List.vue'),
  },
  {
    path: '/event/:subdomain/shop/register',
    name: 'EventShopRegister',
    meta: {
      title: '店舗管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_SHOPS_OFFICE,
    },
    component: () => import('../views/Event/Shop/Account/Register.vue'),
  },
  {
    path: '/event/:subdomain/shop/edit/:id',
    name: 'EventShopEdit',
    meta: {
      title: '店舗管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_SHOPS_OFFICE,
    },
    component: () => import('../views/Event/Shop/Account/Edit.vue'),
  },
  {
    path: '/event/:subdomain/shop/group',
    name: 'EventShopGroup',
    meta: {
      title: '店舗管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_SHOPS_OFFICE,
    },
    component: () => import('../views/Event/Shop/Group/List.vue'),
  },
  {
    path: '/event/:subdomain/shop/group/delete/:id',
    name: 'EventShopGroupDelete',
    meta: {
      title: '店舗管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_SHOPS_OFFICE,
    },
    component: () => import('../views/Event/Shop/Group/Delete.vue'),
  },
  {
    path: '/event/:subdomain/shop/group/add/:id',
    name: 'EventShopGroupAdd',
    meta: {
      title: '店舗管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_SHOPS_OFFICE,
    },
    component: () => import('../views/Event/Shop/Group/Add.vue'),
  },
  //--EVENT
  //--USER
  {
    path: '/event/:subdomain/user',
    name: 'EventUser',
    meta: {
      title: '顧客管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
    },
    component: () => import('../views/Event/User/List.vue'),
  },
  {
    path: '/event/:subdomain/user/register',
    name: 'EventUserRegister',
    meta: {
      title: '顧客管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
      notClientChild: true
    },
    component: () => import('../views/Event/User/Register.vue'),
  },
  {
    path: '/event/:subdomain/user/edit/:customerId',
    name: 'EventUserEdit',
    meta: {
      title: '顧客管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
      notClientChild: true
    },
    component: () => import('../views/Event/User/Edit.vue'),
  },
  {
    path: '/event/:subdomain/user/:customerId',
    name: 'EventUserDetail',
    meta: {
      title: '顧客管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
    },
    component: () => import('../views/Event/User/Detail.vue'),
  },
  {
    path: '/event/:subdomain/user/templates',
    meta: { title: '顧客管理' },
    component: () => import('../views/Event/User/Template/Index.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'EventUserTemplates'
        },
      },
      {
        path: 'list',
        name: 'EventUserTemplates',
        meta: {
          title: '顧客管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
        },
        component: () => import('../views/Event/User/Template/List.vue'),
      },
      {
        path: 'register',
        name: 'EventUserTemplatesRegister',
        meta: {
          title: '顧客管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          useOmise: true,
          childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
        },
        component: () => import('../views/Event/User/Template/Form.vue'),
      },
      {
        path: ':id',
        name: 'EventUserTemplatesEdit',
        meta: {
          title: '顧客管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
        },
        component: () => import('../views/Event/User/Template/Form.vue'),
      },
      {
        path: 'news/register',
        name: 'EventUserTemplatesNewRegister',
        meta: {
          title: '顧客管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          useOmise: true,
          childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
        },
        component: () => import('../views/Event/User/Template/News.vue'),
      },
      {
        path: 'news/:id',
        name: 'EventUserTemplatesNewEdit',
        meta: {
          title: '顧客管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_CUSTOMERS_OFFICE,
        },
        component: () => import('../views/Event/User/Template/News.vue'),
      },
    ]
  },
  //--EVENT
  //--currency
  {
    path: '/event/:subdomain/:directory/currency',
    meta: {
      title: '通貨管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Event/Currency/Index.vue'),
    children: [
      {
        path: '',
        name: 'EventCurrency',
        meta: {
          title: '通貨管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: [RoleConstants.ROLE_CURRENCY_OFFICE, RoleConstants.ROLE_CUSTOMERS_OFFICE, RoleConstants.ROLE_MANAGE_CURRENCY_OFFICE],
        },
        component: () => import('../views/Event/Currency/List.vue'),
      },
      {
        path: 'qr',
        name: 'EventCurrencyQr',
        meta: {
          title: '通貨管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: [RoleConstants.ROLE_CURRENCY_OFFICE, RoleConstants.ROLE_MANAGE_QR_OFFICE],
        },
        component: () => import('../views/Event/Currency/QR/List.vue'),
      },
      {
        path: 'qr/register',
        name: 'EventCurrencyQrRegister',
        meta: {
          title: '通貨管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: [RoleConstants.ROLE_CURRENCY_OFFICE, RoleConstants.ROLE_MANAGE_QR_OFFICE],
        },
        component: () => import('../views/Event/Currency/QR/Form.vue'),
      },
      {
        path: 'qr/:id',
        name: 'EventCurrencyQrEdit',
        meta: {
          title: '通貨管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: [RoleConstants.ROLE_CURRENCY_OFFICE, RoleConstants.ROLE_MANAGE_QR_OFFICE],
        },
        component: () => import('../views/Event/Currency/QR/Form.vue'),
      },
      {
        path: 'onetime-token',
        name: 'EventCurrencyOnetimeToken',
        meta: {
          title: '通貨管理',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: [RoleConstants.ROLE_CURRENCY_OFFICE, RoleConstants.ROLE_MANAGE_QR_OFFICE],
        },
        component: () => import('../views/Event/Currency/OnetimeToken/List.vue'),
      },
    ]
  },
  //--EVENT
  //--COUPON
  {
    path: '/event/:subdomain/coupon',
    name: 'EventCoupon',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: [RoleConstants.ROLE_COUPONS_OFFICE, RoleConstants.ROLE_SHOPS_OFFICE],
    },
    component: () => import('../views/Event/Coupon/List.vue'),
  },
  {
    path: '/event/:subdomain/coupon/use',
    name: 'EventCouponUse',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: [RoleConstants.ROLE_COUPONS_OFFICE, RoleConstants.ROLE_SHOPS_OFFICE],
    },
    component: () => import('../views/Event/Coupon/Use.vue'),
  },
  {
    path: '/event/:subdomain/coupon/register',
    name: 'EventCouponRegister',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: [RoleConstants.ROLE_COUPONS_OFFICE, RoleConstants.ROLE_SHOPS_OFFICE],
    },
    component: () => import('../views/Event/Coupon/Register.vue'),
  },
  {
    path: '/event/:subdomain/coupon/:shopId/edit/:id',
    name: 'EventCouponEdit',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: [RoleConstants.ROLE_COUPONS_OFFICE, RoleConstants.ROLE_SHOPS_OFFICE],
      notClientChild: true
    },
    component: () => import('../views/Event/Coupon/Edit.vue'),
  },
  //--EVENT
  //--POINT
  //--PARENT POINT
  {
    path: '/event/:subdomain/point',
    name: 'EventParentPoint',
    meta: {
      title: 'ポイント',
      childRoles: [RoleConstants.ROLE_POINTS_OFFICE],
    },
    component: () => import('../views/Event/Point/Index.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'EventParentPointHistory'
        },
      },
      {
        path: 'history',
        name: 'EventParentPointHistory',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/History/List.vue'),
      },
      {
        path: 'setting',
        name: 'EventParentPointSet',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/Setting.vue'),
      },
      {
        path: 'store',
        name: 'EventParentPointStore',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/Store/List.vue'),
      },
      {
        path: 'summary',
        name: 'EventParentPointSummary',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/Summary/List.vue'),
      },
      {
        path: 'qr',
        name: 'EventParentPointQR',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/QR/List.vue'),
      },
      {
        path: 'qr/register',
        name: 'EventParentPointQRRegister',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          notClientChild: true
        },
        component: () => import('../views/Event/Point/QR/Form.vue'),
      },
      {
        path: 'qr/:id',
        name: 'EventParentPointQREdit',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/QR/Form.vue'),
      },
      {
        path: 'management',
        name: 'EventParentPointManagement',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          notClientChild: true
        },
        component: () => import('../views/Event/Point/Management/List.vue'),
      },
    ]
  },
  //--CHILD POINT
  {
    path: '/event/:subdomain/:directory/point',
    name: 'EventChildPoint',
    meta: {
      title: 'ポイント',
      childRoles: [RoleConstants.ROLE_POINTS_OFFICE],
    },
    component: () => import('../views/Event/Point/Index.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'EventChildPointHistory'
        },
      },
      {
        path: 'history',
        name: 'EventChildPointHistory',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/History/List.vue'),
      },
      {
        path: 'setting',
        name: 'EventChildPointSet',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/Setting.vue'),
      },
      {
        path: 'store',
        name: 'EventChildPointStore',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/Store/List.vue'),
      },
      {
        path: 'summary',
        name: 'EventChildPointSummary',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/Summary/List.vue'),
      },
      {
        path: 'qr',
        name: 'EventChildPointQR',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/QR/List.vue'),
      },
      {
        path: 'qr/register',
        name: 'EventChildPointQRRegister',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          notClientChild: true
        },
        component: () => import('../views/Event/Point/QR/Form.vue'),
      },
      {
        path: 'qr/:id',
        name: 'EventChildPointQREdit',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
        },
        component: () => import('../views/Event/Point/QR/Form.vue'),
      },
      {
        path: 'management',
        name: 'EventChildPointManagement',
        meta: {
          title: 'ポイント',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          notClientChild: true
        },
        component: () => import('../views/Event/Point/Management/List.vue'),
      },
    ]
  },
  //--EVENT
  //--HISTORY
  {
    path: '/event/:subdomain/:directory/history',
    redirect: '/event/:subdomain/:directory/history/buy',
  },
  {
    path: '/event/:subdomain/:directory/history/buy',
    name: 'EventHistoryBuy',
    meta: {
      title: '購入履歴',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_TRANSACTIONS_OFFICE,
    },
    component: () => import('../views/Event/History/Buy.vue'),
  },
  {
    path: '/event/:subdomain/:directory/history/use',
    name: 'EventHistoryUse',
    meta: {
      title: '支払履歴',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_TRANSACTIONS_OFFICE,
    },
    component: () => import('../views/Event/History/Use.vue'),
  },
  //--EVENT
  //--SUMMARY
  {
    path: '/event/:subdomain/:directory/summary',
    name: 'EventSummary',
    meta: {
      title: '集計',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_AGGREGATES_OFFICE,
    },
    component: () => import('../views/Event/Summary/List.vue'),
  },
  {
    path: '/event/:subdomain/:directory/summary/detail',
    name: 'EventSummaryDetail',
    meta: {
      title: '集計',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_AGGREGATES_OFFICE,
    },
    component: () => import('../views/Event/Summary/Detail.vue'),
  },
  {
    path: '/event/:subdomain/:directory/summary/company',
    name: 'EventSummaryCompany',
    meta: {
      title: '集計',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_AGGREGATES_OFFICE,
    },
    component: () => import('../views/Event/Summary/Company.vue'),
  },
  //--EVENT
  //--NEWS
  {
    path: '/event/:subdomain/:directory/news',
    name: 'News',
    meta: {
      title: 'お知らせ一覧',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/News/List.vue'),
  },
  {
    path: '/event/:subdomain/:directory/news/register',
    name: 'NewsRegister',
    meta: {
      title: 'お知らせ新規登録',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/News/Register.vue'),
  },
  {
    path: '/event/:subdomain/:directory/news/:id',
    name: 'NewsEdit',
    meta: {
      title: 'お知らせ編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/News/Edit.vue'),
  },
  //--EVENT
  //--LIST STORE ATTEND
  {
    path: '/event/:subdomain/:directory/shop',
    name: 'EventStoreAttendList',
    meta: {
      title: '参加店舗リスト',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Event/AttendStore/List.vue'),
  },
  {
    path: '/event/:subdomain/:directory/shop/register',
    name: 'EventStoreAttendRegister',
    meta: {
      title: '参加店舗リスト',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Event/AttendStore/Register.vue'),
  },
  //--SHOP
  {
    path: '/shop/event/dashboard',
    name: 'ShopEventDashboardParent',
    meta: {
      title: 'ダッシュボード',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Event/DashboardParent.vue'),
  },
  {
    path: '/shop/event/:directory/dashboard',
    name: 'ShopEventDashboardChild',
    meta: {
      title: 'ダッシュボード',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Event/DashboardChild.vue'),
  },
  //--SHOP
  //--SETTING
  {
    path: '/shop/setting',
    meta: {
      title: '店舗設定',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Setting/Index.vue'),
    children: [
      {
        path: '',
        redirect: 'info',
      },
      {
        path: 'info',
        name: 'ShopSettingInfo',
        meta: {
          title: '店舗設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
        },
        component: () => import('../views/Shop/Setting/Info.vue'),
      },
      {
        path: 'default',
        name: 'ShopSettingDefault',
        meta: {
          title: '店舗設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
        },
        component: () => import('../views/Shop/Setting/Default.vue'),
      },
      {
        path: 'base',
        name: 'ShopSettingBase',
        meta: {
          title: '店舗設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
          shopSetting: 'basic',
        },
        component: () => import('../views/Shop/Setting/Base.vue'),
      },
      {
        path: 'detail',
        name: 'ShopSettingDetail',
        meta: {
          title: '店舗設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
          shopSetting: 'advanced',
        },
        component: () => import('../views/Shop/Setting/Detail.vue'),
      },
    ],
  },
  //--SHOP
  //--USER
  {
    path: '/shop/user',
    name: 'ShopUser',
    meta: {
      title: '顧客管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      customerFlag: true,
    },
    component: () => import('../views/Shop/User/List.vue'),
  },
  {
    path: '/shop/user/:id',
    name: 'ShopUserDetail',
    meta: {
      title: '顧客管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      customerFlag: true,
    },
    component: () => import('../views/Shop/User/Detail.vue'),
  },
  //--SHOP
  //--GALLERY
  {
    path: '/shop/gallery/category',
    name: 'ShopGalleryCategory',
    meta: {
      title: 'ギャラリー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Gallery/Category.vue'),
  },
  {
    path: '/shop/gallery/list',
    name: 'ShopGalleryList',
    meta: {
      title: 'ギャラリー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Gallery/List.vue'),
  },
  {
    path: '/shop/gallery/register',
    name: 'ShopGalleryRegister',
    meta: {
      title: 'ギャラリー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Gallery/Register.vue'),
  },
  {
    path: '/shop/gallery/edit/:id',
    name: 'ShopGalleryEdit',
    meta: {
      title: 'ギャラリー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Gallery/Edit.vue'),
  },
  //--SHOP
  //--MENU
  {
    path: '/shop/menu/category',
    name: 'ShopMenuCategory',
    meta: {
      title: 'メニュー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Menu/Category.vue'),
  },
  {
    path: '/shop/menu/list',
    name: 'ShopMenu',
    meta: {
      title: 'メニュー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Menu/List.vue'),
  },
  {
    path: '/shop/menu/register',
    name: 'ShopMenuRegister',
    meta: {
      title: 'メニュー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Menu/Register.vue'),
  },
  {
    path: '/shop/menu/edit/:id',
    name: 'ShopMenuEdit',
    meta: {
      title: 'メニュー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Menu/Edit.vue'),
  },
  //--SHOP
  //--COUPON
  {
    path: '/shop/coupon',
    name: 'ShopCoupon',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      shopMenu: 'coupon',
    },
    component: () => import('../views/Shop/Coupon/List.vue'),
  },
  {
    path: '/shop/coupon/use',
    name: 'ShopCouponUse',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      shopMenu: 'coupon',
    },
    component: () => import('../views/Shop/Coupon/Use.vue'),
  },
  {
    path: '/shop/coupon/register',
    name: 'ShopCouponRegister',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      shopMenu: 'coupon',
    },
    component: () => import('../views/Shop/Coupon/Register.vue'),
  },
  {
    path: '/shop/coupon/edit/:id',
    name: 'ShopCouponEdit',
    meta: {
      title: 'クーポン',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
      shopMenu: 'coupon',
    },
    component: () => import('../views/Shop/Coupon/Edit.vue'),
  },
  //--SHOP
  //--COUPON
  {
    path: '/shop/point/history',
    name: 'ShopParentPointHistory',
    meta: {
      title: 'ポイント',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Event/Point/History/List.vue'),
  },
  {
    path: '/shop/:directory/point/history',
    name: 'ShopChildPointHistory',
    meta: {
      title: 'ポイント',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Event/Point/History/List.vue'),
  },
  //--SHOP
  //--HISTORY
  {
    path: '/shop/:directory/history',
    name: 'ShopHistory',
    meta: {
      title: '支払履歴',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/History/Index.vue'),
  },
  //--SHOP
  //--SUMMARY
  {
    path: '/shop/:directory/summary',
    name: 'ShopSummary',
    meta: {
      title: '集計',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Event/Summary/List.vue'),
  },
  {
    path: '/shop/:directory/summary/detail',
    name: 'ShopSummaryDetail',
    meta: {
      title: '集計',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Event/Summary/Detail.vue'),
  },
  //--SHOP
  //--QR
  {
    path: '/shop/qr',
    name: 'ShopQr',
    meta: {
      title: 'QRコード',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
    },
    component: () => import('../views/Shop/Qr/Index.vue'),
  },
  //--SHOP
  //--News
  {
    path: '/shop/:directory/news',
    name: 'ShopNews',
    meta: {
      title: 'お知らせ一覧',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      shopMenu: 'news',
    },
    component: () => import('../views/News/List.vue'),
  },
  {
    path: '/shop/:directory/news/register',
    name: 'ShopNewsRegister',
    meta: {
      title: 'お知らせ新規登録',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
      shopMenu: 'news',
    },
    component: () => import('../views/News/Register.vue'),
  },
  {
    path: '/shop/:directory/news/:id',
    name: 'ShopNewsEdit',
    meta: {
      title: 'お知らせ編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/News/Edit.vue'),
  },
  //--ACCOUNT
  {
    path: '/account/user',
    name: 'AccountUser',
    meta: {
      title: 'アカウント設定',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
      requiredAuth: true,
      isGmoOrOfficeLoginStore: true,
    },
    component: () => import('../views/Account/User.vue'),
  },
  {
    path: '/account/company',
    name: 'AccountCompany',
    meta: {
      title: '企業設定',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
      isGmoOrOfficeLoginStore: true,
    },
    component: () => import('../views/Account/Company.vue'),
  },
  {
    path: '/account/user/cpm',
    meta: {
      title: 'ユーザー設定',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Account/Index.vue'),
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'AccountUserCpm',
        meta: {
          title: 'ユーザー設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
        },
        component: () => import('../views/Account/List.vue'),
      },
      {
        path: 'register',
        name: 'AccountUserCpmRegister',
        meta: {
          title: 'ユーザー設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
          useCpm: true
        },
        component: () => import('../views/Account/CPM/Register.vue'),
      },
      {
        path: 'edit/:id',
        name: 'AccountUserCpmEdit',
        meta: {
          title: 'ユーザー設定',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
          requiredAuth: true,
        },
        component: () => import('../views/Account/CPM/Edit.vue'),
      },
    ]
  },
  {
    path: '/account/log',
    name: 'AccountUserLog',
    meta: {
      title: 'ログインログ',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE,
      requiredAuth: true,
    },
    component: () => import('../views/Account/Log.vue'),
  },
  //--NOTIFICATION
  {
    path: '/notification',
    name: 'Notification',
    meta: {
      title: '通知一覧',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
    },
    component: () => import('../views/Notification/List.vue'),
  },
  {
    path: '/notification/register',
    name: 'NotificationRegister',
    meta: {
      title: '通知新規登録',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      eventFlag: true,
    },
    component: () => import('../views/Notification/Register.vue'),
  },
  //--SETTING(EVENT)
  {
    path: '/setting/event',
    name: 'SettingEvent',
    meta: {
      title: 'イベント管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_EVENTS_OFFICE,
    },
    component: () => import('../views/Setting/Event/List.vue'),
  },
  {
    path: '/setting/event/:subdomain/child',
    name: 'SettingEventChild',
    meta: {
      title: 'イベント管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_EVENTS_OFFICE,
    },
    component: () => import('../views/Setting/Event/ListChild.vue'),
  },
  {
    path: '/setting/event/register',
    name: 'SettingEventParentRegister',
    meta: {
      title: '親イベント新規登録',
      roles: RoleConstants.ROLE_GMO,
      requiredAuth: true,
      notClientChild: true,
    },
    component: () => import('../views/Setting/Event/Register/Parent/Index.vue'),
  },
  {
    path: '/setting/event/:subdomain/register/child',
    meta: { title: '子イベント新規登録' },
    component: () => import('../views/Setting/Event/Register/Child/Index.vue'),
    children: [
      {
        path: '',
        name: 'SettingEventChildRegister',
        redirect: 'base',
      },
      {
        path: 'base',
        name: 'SettingEventChildRegisterBase',
        meta: {
          title: '子イベント新規登録',
          roles: RoleConstants.ROLE_GMO,
          requiredAuth: true,
        },
        component: () => import('../views/Setting/Event/Register/Child/Base.vue'),
      },
      {
        path: 'detail',
        name: 'SettingEventChildRegisterDetail',
        meta: {
          title: '子イベント新規登録',
          roles: RoleConstants.ROLE_GMO,
          requiredAuth: true,
        },
        component: () => import('../views/Setting/Event/Register/Child/Detail.vue'),
      },
    ],
  },
  {
    path: '/setting/event/edit/:subdomain',
    name: 'SettingEventParentEdit',
    meta: {
      title: '親イベント編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_EVENTS_OFFICE,
      notClientChild: true,
    },
    component: () => import('../views/Setting/Event/Edit/Parent/Index.vue'),
  },
  {
    path: '/setting/event/:subdomain/edit/:directory',
    meta: {
      title: '子イベント編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_EVENTS_OFFICE,
    },
    component: () => import('../views/Setting/Event/Edit/Child/Index.vue'),
    children: [
      {
        path: '',
        name: 'SettingEventChildEdit',
        redirect: 'base',
      },
      {
        path: 'base',
        name: 'SettingEventChildEditBase',
        meta: {
          title: '子イベント編集',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_EVENTS_OFFICE,
        },
        component: () => import('../views/Setting/Event/Edit/Child/Base.vue'),
      },
      {
        path: 'detail',
        name: 'SettingEventChildEditDetail',
        meta: {
          title: '子イベント編集',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_EVENTS_OFFICE,
        },
        component: () => import('../views/Setting/Event/Edit/Child/Detail.vue'),
      },
    ],
  },
  //--SETTING(USER)
  {
    path: '/setting/user',
    name: 'SettingUser',
    meta: {
      title: 'ユーザー管理',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_USERS_OFFICE,
    },
    component: () => import('../views/Setting/User/List.vue'),
  },
  {
    path: '/setting/user/register',
    meta: {
      title: 'ユーザー新規登録',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_USERS_OFFICE,
    },
    component: () => import('../views/Setting/User/Register/Index.vue'),
    children: [
      {
        path: '',
        redirect: 'select',
      },
      {
        path: 'select',
        name: 'SettingUserRegisterSelect',
        meta: {
          title: 'ユーザー新規登録',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_USERS_OFFICE,
        },
        component: () => import('../views/Setting/User/Register/Select.vue'),
      },
      {
        path: 'client',
        name: 'SettingUserRegisterClient',
        meta: {
          title: 'ユーザー新規登録',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_USERS_OFFICE,
        },
        component: () => import('../views/Setting/User/Register/Client.vue'),
      },
      {
        path: 'master',
        name: 'SettingUserRegisterMaster',
        meta: {
          title: 'ユーザー新規登録',
          roles: RoleConstants.ROLE_GMO,
          requiredAuth: true,
          childRoles: RoleConstants.ROLE_USERS_OFFICE,
        },
        component: () => import('../views/Setting/User/Register/Master.vue'),
      },
      {
        path: 'shop',
        name: 'SettingUserRegisterShop',
        meta: {
          title: 'ユーザー新規登録',
          roles: RoleConstants.ROLE_GMO_OR_OFFICE,
          requiredAuth: true,
          childRoles: [RoleConstants.ROLE_USERS_OFFICE, RoleConstants.ROLE_SHOPS_OFFICE],
        },
        component: () => import('../views/Setting/User/Register/Shop.vue'),
      },
    ],
  },
  {
    path: '/setting/user/edit/master/:id',
    name: 'SettingUserEditMaster',
    meta: {
      title: 'ユーザー編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_USERS_OFFICE,
    },
    component: () => import('../views/Setting/User/Edit/Master.vue'),
  },
  {
    path: '/setting/user/edit/client/:id',
    name: 'SettingUserEditClient',
    meta: {
      title: 'ユーザー編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: RoleConstants.ROLE_USERS_OFFICE,
    },
    component: () => import('../views/Setting/User/Edit/Client.vue'),
  },
  {
    path: '/setting/user/edit/shop/:id',
    name: 'SettingUserEditShop',
    meta: {
      title: 'ユーザー編集',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE,
      requiredAuth: true,
      childRoles: [RoleConstants.ROLE_USERS_OFFICE, RoleConstants.ROLE_SHOPS_OFFICE],
    },
    component: () => import('../views/Setting/User/Edit/Shop.vue'),
  },
  //--ERROR
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404エラー',
      roles: RoleConstants.ROLE_GMO_OR_OFFICE_OR_STORE_OR_GROUP,
    },
    component: () => import('../views/Error/404.vue'),
  },
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/500',
    name: '500',
    meta: { title: '500' },
    component: () => import('../views/Error/500.vue'),
  },
  //--SAMPLE
  {
    path: '/module',
    name: 'Module',
    component: () => import('../views/Sample/Module.vue'),
  },
  {
    path: '/user',
    name: 'Sample',
    component: () => import('../views/Sample/Sample.vue'),
    children: [
      {
        path: 'select',
        component: () => import('../views/Sample/SampleSelect.vue'),
      },
      {
        path: 'input',
        component: () => import('../views/Sample/SampleInput.vue'),
      },
    ],
  },
  {
    path: '/help',
    name: 'Help',
    redirect: '404',
  },
];
