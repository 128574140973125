import mutations from '../notification/mutations';
import actions from '../notification/actions';
import getters from '../notification/getters';

export const initialNotification = () => ({
  notificationList: {
    list: [],
    count: 0,
  },
  userNotificationList: {
    list: [],
    count: 0,
  },
});

export default {
  state: initialNotification(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
