import axios from 'axios';
import { set } from 'lodash';
import store from '@/store';
import router from '@/router';
import { httpCode } from '@/config/httpCode';
import loading from '@/helpers/loading';
import { getToken } from '@/helpers/http';
import message from '@/helpers/message';

let http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  withCredentials: false,
});

let failedQueue = [];
let isRefreshing = false;
let timeoutId = null;

http.interceptors.request.use((config) => {
  const token = getToken() || store.getters['auth/token'];
  if (token !== store.getters['auth/token']) {
    store.commit('auth/SET_TOKEN', token);
    store.dispatch('auth/getUserInfor');
    router.push({ name: '404' });
  }
  if (!config.isWithCustomConfig) {
    const subdomain = store.getters['common/subdomain'];
    const isGmoOrOfficeLoginStore = store.getters['common/isGmoOrOfficeLoginStore'] || store.getters['common/isOldEvent'];
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
      ...(subdomain && subdomain !== "management" && { 'Sub-Domain': subdomain }),
      ...((isGmoOrOfficeLoginStore && store.getters['shop/newShopId']) && { 'shopId': store.getters['shop/newShopId'] }
      ),
    };
  }
  if (config.isMultipartContent) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
    store.dispatch('DELETE_ALERT');
    if (!config.notLoading && !isRefreshing) {
      timeoutId = loading.show();
      store.dispatch('common/setButtonLoading', true);
    }
  }
  if (config.isEventApi) {
    store.dispatch('event/resetErrorFields');
  } else {
    store.dispatch('common/resetErrorFields');
  }
  return config;
});

function showErrors(errors) {
  let newErrors = errors;
  if (errors?.[0]?.includes(' |--| ')) newErrors = errors[0].split(' |--| ');
  const convertedErrors = [...new Set(newErrors)];
  convertedErrors?.forEach((error) => {
    store.dispatch('ADD_ALERTS', {
      message: error,
      type: 'error',
    });
  });
}

function removeDoubleErrors(errors) {
  return errors.filter(function(item, pos) {
    return errors.indexOf(item) == pos;
  })
}

function normalizeError(errors) {
  if (!errors) {
    return [];
  }
  if (Array.isArray(errors)) {
    return errors.map((item) => item.message);
  }
  return [errors.message];
}

function setErrorFields(errors, isMessageConverted = false) {
  if (!errors) {
    return {};
  }
  let apiInputErrors = {};
  if (Array.isArray(errors)) {
    errors.map((item) => {
      set(apiInputErrors, item.name, true)
      if (isMessageConverted) apiInputErrors[item.name] = true;
    });
  }
  return apiInputErrors;
}

const processQueue = (error) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve();
    }
  });
  failedQueue = [];
};

const forceLogin = (errors) => {
  const isOldEvent = localStorage.getItem('isOldEvent');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('shopId');
  localStorage.removeItem('isOldEvent');
  if (JSON.parse(isOldEvent)) {
    localStorage.setItem('SUBDOMAIN', 'management');
  }
  store.commit('RESET_ALL');
  router.push({ name: 'Login' }).then(() => showErrors(errors)).catch(() => {});
}

http.interceptors.response.use(
  (response) => {
    if (!response.config.notLoading) {
      store.dispatch('common/setButtonLoading', false);
      if (timeoutId) {
        loading.clear(timeoutId);
      }
    }
    return response;
  },
  (error) => {
    if (timeoutId) {
      loading.clear(timeoutId);
    }
    store.dispatch('common/setButtonLoading', false);
    store.dispatch('common/setLoading', false);
    let errors = normalizeError(error?.response?.data?.errors);
    if (error.config.url === '/token') {
      errors = removeDoubleErrors(errors);
    }
    const originalRequest = error.config;
    switch (error?.response?.status) {
      case httpCode.SYSTEM_ERROR: {
        router.push({ name: '500' });
        break;
      }
      case httpCode.BAD_REQUEST: {
        const apiInputErrors = setErrorFields(error?.response?.data?.errors, originalRequest?.isMessageConverted);
        if (error.config.isEventApi) {
          store.dispatch('event/setErrorFields', apiInputErrors)
        } else {
          store.dispatch('common/setErrorFields', apiInputErrors)
        }
        showErrors(errors);
        break;
      }
      case httpCode.NOT_FOUND:
        if(error.response.config.isWithCustomConfig) return;
        if(error.config.isWithNotSeenHistoryPayment) {
          store.dispatch('history/resetHistoryPayments');
          store.dispatch('history/setIsHistoryPaymentsNotSeen', true);
          return Promise.reject(error);
        }
        localStorage.removeItem('idRequestExport')
        router.push({ name: '404' });
        break;
      case httpCode.UNAUTHORIZED:
        if (originalRequest.notRefresh) {
          store.commit('auth/RESET_LOCAL_STORAGE');
          router.push({ name: 'Login' }).then(() => message.showError('outOfLoginSession')).catch(() => {});
        } else if (
          !originalRequest._retry &&
          !originalRequest.notRefresh
        ) {
          if (isRefreshing) {
            return new Promise(function(resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then(() => {
                return http(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
          originalRequest._retry = true;
          isRefreshing = true;
          return new Promise(function(resolve) {
            return store
              .dispatch('auth/refreshToken')
              .then(() => {
                processQueue();
                resolve(http(originalRequest));
              })
              .catch(async (error) => {
                forceLogin(errors);
                processQueue(error);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        break;
      case httpCode.TOO_MANY_REQUEST:
        forceLogin();
        showErrors(errors);
        break;
      default:
        showErrors(errors);
        break;
    }
    if (originalRequest.notRefresh) {
      return Promise.reject('Refresh Token Failed');
    }
    return null;
  }
);

export default http;
