import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialSettingUser = () => ({
  userDetail: {},
  userList: [],
  userCount: null,
  userCountSearch: null,
  userActivities: []
});

export default {
  state: initialSettingUser(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
