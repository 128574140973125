import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialHistory = () => ({
  historyCharges: [],
  historyChargesCount: 0,
  historyChargeDetail: {},
  historyPayments: [],
  historyPaymentsCount: 0,
  historyPaymentDetail: {},
  historyPaymentTotal: 0,
  historyPaymentBalance: 0,
  isHistoryPaymentNotSeen: false,
  sortText: '',
  sortType: '',
  ticketList: [],
  methodList: [],
  totalAdded: 0,
});

export default {
  state: initialHistory(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
