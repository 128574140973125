import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialParentPoint = () => ({
  categoriesList: {
    list: [],
    count: 0,
  },
  parentPointDetail: {},
  customerList: {
    list: [],
    count: 0,
  },
  historyPointList: {
    list: [],
    count: 0,
    point: 0,
    plannedPoint: 0
  },
  pointQRCodePulldown: [],
  historyPointTypeList: {
    list: []
  },
  shopPointList: {
    list: []
  },
  pointStoreList: {
    list: [],
    count: 0
  },
  aggregratePointDetail: {},
  customerPointActivities: [],
  pointQRList: {
    list: [],
    count: 0
  },
  pointQrDetail: {},
  sortText: '',
  sortType: '',
});

export default {
  state: initialParentPoint(),
  mutations,
  actions,
  getters,
  namespaced: true
};
