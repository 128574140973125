import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialAccount = () => ({});

export default {
  state: initialAccount(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
