import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialCommon = () => ({
  isLoading: false,
  isButtonLoading: false,
  errors: [],
  errorFields: {},
  isErrorModalOpened: false,
  cardTemplateList: [],
  uploadedImage: {},
  chargeAmountChoices: [],
  genres: [],
  paymentCycles: [],
  prefectures: [],
  municipalities: [],
  topicTemplates: [],
  backgroundTemplates: [],
  userPrivileges: [],
  uploadErrors: [],
  convenienceStores: [],
  subdomain: localStorage.getItem('SUBDOMAIN') || window.location.host.split('.')[0] || null,
  isOldEvent: JSON.parse(localStorage.getItem('isOldEvent')) || null,
});

export default {
  state: initialCommon(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
