import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialQrCode = () => ({
  qrCodeList: [],
  qrCodeCount: 0,
});

export default {
  state: initialQrCode(),
  mutations,
  actions,
  getters,
  namespaced: true,
};
